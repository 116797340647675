/**
 * Dashboard Page entered on login contains:
   -Header
   -Instructor usertype contains KPICards
   -Created/Enrolled courses(My course and course in progress)
   -Reccommended Courses
   -Metrics, List of students,instructor and courses(id admin user)
**/

//Import axios for API's
import axios from "axios";

//Import React components
import React, {useEffect, useState} from 'react';

//Import MDB components
import {useMDBCookies} from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";
import {MDBContainer} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";

//Admin Dashboard
import AdminDasboard from "../components/dashboard/AdminDashboard";

//Import components required to render page
import Header from '../components/utils/Header';
import Slider from '../components/utils/Slider';
import KPICard from '../components/dashboard/KPICards';

function Dashboard() {

    //Get user-id and user-type saved as cookies on login
    const [userId] = useMDBCookies('user-id', '');
    const [userType] = useMDBCookies('user-type', '');

    //UseState react hook lets to add state variables-userCourses(all enrolled courses),myCourses(user courses without progress)
    const [userCourses, setUserCourses] = useState([]);
    const [allCourses, setAllCourses] = useState([]);
    const [myCourse, setMyCourse] = useState([]);
    const [courseInProgress, setCourseInProgress]= useState([]);
    const [courseSet, setCourseSet]= useState(false);

    //useEffect react hook renders when page loads and rerenders when parameters described changes(userType,userId)
    useEffect(() => {
        //data to send to post request
        let userData = {
            userId: userId,
            userType: userType
        };
        //Post request to get-courses API response with user courses or created courses based on usertype
        axios.post("https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/courses", JSON.stringify(userData))
            .then(response => setUserCourses(response.data.courseData));
    }, [userId, userType]);

    useEffect(() => {
        //data to send to post request
        let userData = {
            userId: -1,
            userType: "admin"
        };
         //Post request to get-courses API response all courses (usertype admin)
        axios.post("https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/courses",JSON.stringify(userData))
            .then(response => setAllCourses(response.data.courseData));
    }, []);

    //useEffect react hook renders when page loads and rerenders when parameters described changes(userCourses)
    useEffect(() => {
        //Check for progress of course and classify
       if(userCourses.length>0 && !courseSet)
       {
          userCourses.forEach((course,index)=>{
            if(((course.courseCompletedDuration/course.courseDuration)*100).toFixed(0)>0)
            {
                courseInProgress.push(course)
            }
            else{
                myCourse.push(course)
            }
          })
          setCourseSet(true)
       }
    }, [userCourses]);

    //Headers based on usertype declared
    let topSliderHeader = "Courses in Progress";
    let bottomSliderHeader = "Recommended Courses";
    let scrollerHeader = "My Courses";
    if (userType === 'instructor') {
        topSliderHeader = "Created Courses";
        bottomSliderHeader = "All Courses";
        scrollerHeader = "Courses Pending Approval";
    }

    //Return Admin dashboard if usertype admin
    if (userType !== 'admin')
    //Return no courses available if userCourses array len 0
     if(userCourses.length > 0)
     //
        if ((allCourses.length > 0))
            return (
                <div>
                    <Header/>
                    {userType === 'instructor'&&
                    <>
                     <KPICard />
                    <MDBContainer className="d-flex flex-wrap justify-content-between mt-4">
                        <div className="col-12">
                            <div>
                                <div className={"d-flex flex-column"}>
                                    <div className={"mt-2"} style={{fontSize: "24px", fontWeight: 500, textAlign: "start"}}>
                                        {topSliderHeader}
                                    </div>
                                    <Slider courses={userCourses} progress={true} view/>
                                    <div className={"mt-2"} style={{fontSize: "24px", fontWeight: 500, textAlign: "start"}}>
                                        {bottomSliderHeader}
                                    </div>
                                    <Slider courses={allCourses}/>
                                </div>
                            </div>
                        </div>
                    </MDBContainer>
                    </>
                 }
                 {userType === 'student'&&
                  <MDBContainer className="d-flex flex-wrap justify-content-between mt-4">
                  <div className="col-12">
                      <div>
                          <div className={"d-flex flex-column"}>
                              <div className={"mt-2"} style={{fontSize: "24px", fontWeight: 500, textAlign: "start"}}>
                                  {topSliderHeader}
                              </div>
                              <Slider courses={courseInProgress} progress={true} view/>
                              <div className={"mt-2"} style={{fontSize: "24px", fontWeight: 500, textAlign: "start"}}>
                                  {scrollerHeader}
                              </div>
                              <div>
                                  <Slider courses={myCourse} progress={true} view/>
                              </div>
                              <div className={"mt-2"} style={{fontSize: "24px", fontWeight: 500, textAlign: "start"}}>
                                  {bottomSliderHeader}
                              </div>
                              <Slider courses={allCourses}/>
                          </div>
                      </div>
                  </div>
              </MDBContainer>
                 }
                </div>
            );
        else
            return (
                <>
                <Header/>
                <div className={"mt-2"} style={{fontSize: "24px", fontWeight: 500, textAlign: "start"}}>
                {topSliderHeader}
                </div>
                <h3>No courses available.</h3>
                <div className={"mt-2"} style={{fontSize: "24px", fontWeight: 500, textAlign: "start"}}>
                {bottomSliderHeader}
                </div>
                <h3>No courses available.</h3>
                </>
            );
       else
        return (
            <>
            <Header/>
            <div className={"mt-2"} style={{fontSize: "24px", fontWeight: 500, textAlign: "start"}}>
            {topSliderHeader}
            </div>
            <h3>No courses available.</h3>
            <div className={"mt-2"} style={{fontSize: "24px", fontWeight: 500, textAlign: "start"}}>
            {bottomSliderHeader}
            </div>
            <Slider courses={allCourses}/>
            </>
        );
    else
        return (
            <div>
               <AdminDasboard />
            </div>
        )
}

export default Dashboard;