/**
 * Create Lecture Page used by instructor to create lectures contains:
   -Accordians with Sections
   -Accordian Item with Lecture 
   -Add section and lecture buttons
   -Save and Next button
 * Accessed by instructor from stepper.
**/

//Import axios for API's
import axios from "axios";

//Import React components
import React, {useState, useEffect} from "react";

//Import MDB components
import {useMDBCookies} from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";
import {MDBBtn, MDBScrollbar} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";

//Import components required to render page
import Section from "../components/createCourse/Section";

function CreateLectures({courseId, save, setSave}) {
    //UseState react hook lets to add state variables-sectionId(default 1),sections,courseTitle
    const [sectionId, setSectionId] = useState(1);
    const [sections, setSections] = useState([{}]);
    const [courseTitle, setCourseTitle] = useState("");

    //Get user-id saved as cookies on login
    const [userId] = useMDBCookies('user-id', '');

    //useEffect react hook renders when page loads and rerenders when parameters described changes(courseId,userId)
    useEffect(() => {
        if (courseId !== '') {
            axios.get(`https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/course?courseId=${courseId}&userId=${userId}`)
                .then((response) => {setCourseTitle(response["data"]["courseTitle"]);});
        }
    }, [userId, courseId]);

    //update section variables 
    const updateSections = (section, id) => {
        let newState = [...sections];
        newState[id] = section;
        setSections(newState);
    };

    //create Section fuction called on saving to post lecture details with axios and create-lecture API
    const createSections = () => {
        axios.post("https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/create-lectures/",JSON.stringify({courseId: courseId, sections: sections}))
             .then(response => setSave(true));
    };

    return (
        <MDBScrollbar suppressScrollX style={{height: window.outerHeight}}>
            <h3 className="my-4">
                Create Lectures
            </h3>
            {[...Array(sectionId)].map((el, i) => (
                <Section key={i} id={i + 1} addSection={updateSections} courseTitle={courseTitle} courseId={courseId} />
            ))}

            <div className="d-flex justify-content-center py-3">
                <MDBBtn
                    color="light" rounded className={"mx-2 text-primary"}
                    onClick={() => {setSections([...sections, {}]); setSectionId(sectionId + 1);}}
                >
                    Add Section
                </MDBBtn>
                <MDBBtn color="primary" onClick={() => createSections()} disabled={save} rounded className={"mx-2"}>
                    Save
                </MDBBtn>
            </div>
        </MDBScrollbar>
    );
}

export default CreateLectures;
