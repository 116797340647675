/**
 * CourseZen-MapMyClasses
 * App.js contains all routing ,ref and contexts information
**/

//Import React modules
import React, {createContext, useState, useRef} from 'react';
import {Route, Routes} from "react-router-dom";

//Import Pages and CSS file
import './App.css';
import AboutCourse from './pages/AboutCourse';
import CreateCourse from './pages/CreateCourse';
import CreateLectures from './pages/CreateLectures';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import Profile from './pages/Profile';
import ViewCourse from './pages/ViewCourse';

//Import all Components
//Dashboard Components
import ListofInstructor from './components/dashboard/ListofInstructor';
import ListofStudents from './components/dashboard/ListofStudents';
import ListofCourse from './components/dashboard/ListofCourse';
import AdminDasboard from './components/dashboard/AdminDashboard';
//View Course Components
import DownloadCertificate from './components/viewCourse/DownloadCertificate';
//CreateCourse Components
import AddLectureQuestions from './components/createCourse/AddLectureQuestions';
import CourseStructure from './components/createCourse/CourseStructure';
import CourseDetails from './components/createCourse/CourseDetails';
import SupplementaryMaterial from './components/createCourse/AddSupplementaryMaterial';
//Common Components
import SearchResult from './components/utils/SearchResult';
import Announcement from './components/utils/Announcement';

//Context object lets to pass information without passing props
export const UrlContext = createContext();

function App() {
    //UseState react hook lets to add state variable-App state variables can be accessed through UrlContext
    const [show, setShow] = useState(false);
    const [url, setUrl] = useState([]);
    const [isLogin, setLogin] = useState(false);
    const [lectureDesc, setLectureDesc] = useState([]);
    const [lectureTitle, setLectureTitle] = useState([]);
    const [selectValue, setSelectValue] = useState(0);
    const [lastsection, setLastSection] = useState(false);
    const [questionModal, setQuestionModal] = useState(false);
    const [index, setIndex] = useState(0);

    //UseRef react hook lets to pass value not required for rendering-playerRef used for video element
    const playerRef = useRef();

    return (

        <div className="App">
            {/* Provider React component that allows consuming components to subscribe to context changes */}
            <UrlContext.Provider
                value={{
                    show, setShow, url, setUrl, isLogin, setLogin, lectureDesc, setLectureDesc,
                    lectureTitle, setLectureTitle, selectValue, setSelectValue, lastsection, setLastSection,
                    questionModal, setQuestionModal, index, setIndex, playerRef
                }}
            >
                {/* All routing declaration */}
                <Routes>
                    {/* Pages */}
                    <Route path='/' element={<Home/>}/>
                    <Route path='/profile' element={<Profile/>}/>
                    <Route path='/dashboard' element={<Dashboard/>}/>
                    <Route path="/about-course/:courseId" element={<AboutCourse/>}/>
                    <Route path="/view-course/:courseId" element={<ViewCourse/>}/>
                    <Route path="/create-course" element={<CreateCourse/>}/>
                    <Route path="/create-lectures/:courseId" element={<CreateLectures/>}/>
                    {/* Components */}
                    {/* Create Course */}
                    <Route path="/course-structure" element={<CourseStructure/>}/>
                    <Route path='/course-details' element={<CourseDetails/>}/>
                    <Route path="/lecture-questions" element={<AddLectureQuestions/>}/>
                    <Route path="/supplementary" element={<SupplementaryMaterial/>}/>
                    {/* View Course */}
                    <Route path="/download-certificate" element={<DownloadCertificate/>}/>
                    {/* Admin Dashboard */}
                    <Route path="/admin-dashboard" element={<AdminDasboard/>} />
                    <Route path="/Course-list" element={<ListofCourse/>} />
                    <Route path="/instructors-list" element={<ListofInstructor/>} />
                    <Route path="/students-list" element={<ListofStudents/>} />
                    {/* Search */}
                    <Route path='/search-result/:searchWord' element={<SearchResult/>}/>
                    {/* Tabs */}
                    <Route path='/announcement' element={<Announcement/>}/>
                </Routes>
            </UrlContext.Provider>
        </div>
    );
}

export default App;
