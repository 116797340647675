import React, {useEffect, useState} from "react";
import {
    MDBCard, MDBCardTitle, MDBCardText,MDBChart
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import axios from "axios";
import LoadingScreen from "../utils/LoadingScreen";
import {useMDBCookies} from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";
import icoutlineschool from "../../assets/ic_outline-school.png";
import icoutlineuser from "../../assets/ic_outline-supervised-user-circle.png";
import studentsinteraction from "../../assets/students-interactions.png";


function KPICard() {
    const [courseList, setCourseList] = useState({});
    const [userId] = useMDBCookies('user-id', '');
    const [userType] = useMDBCookies('user-type', '');
    const [userCourses, setUserCourses] = useState([]);
    const [studentCount,setStudentCount] = useState({});
    const [courseCount,setCourseCount] = useState({});
    

    useEffect(() => { // GET request using axios inside useEffect React hook
      axios.get(`https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/total-studentCount?instructorId=${userId}`)
          .then(response => setStudentCount(response.data));
      // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  

  useEffect(() => { // GET request using axios inside useEffect React hook
    axios.get(`https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/course-count?instructorId=${userId}`)
        .then(response => setCourseCount(response.data));
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
}, []);

    useEffect(() => {
      let userData = {
          userId: userId,
          userType: userType
      };
      axios.post("https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/courses", JSON.stringify(userData))
          .then(response => setUserCourses(response.data.courseData));
  }, [userId, userType]);

    useEffect(() => {
        let userData = {
            userId: -1,
            userType: "admin"
        };
        axios.post(`https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/courses`,
            JSON.stringify(userData))
            .then(response => setCourseList(response.data.courseData));
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

    
    if (courseList.length > 0 && userType === 'instructor' && courseCount.length>0 && studentCount.length>0)
        return (
            <>
               <div>
                  <div className="col-md-12 col-sm-9" style={{width: "100%", height: "100%"}}>
                        <div className='d-flex flex-wrap justify-content-around'>
                            <MDBCard className='mt-4 mb-3 p-2 ms-5 col-12 col-md-3'>
                                <div className="d-flex flex-coloumn">
                                  <img className="m-2" src={icoutlineschool} height="100px" alt="MapMyClasses"/>
                                     <span className="justify-content-center align-items-center">
                                    <MDBCardTitle className='m-2'>Created Courses</MDBCardTitle>

                                    <MDBCardText style={{fontSize: "50px"}}>{courseCount[0].no_of_courses}</MDBCardText>
                                    </span>
                                    </div>
                                </MDBCard>
                            <MDBCard className='mt-4 mb-3 p-2 ms-5 col-12 col-md-3'>
                                    <div className="d-flex flex-coloumn">
                                  <img className="m-2"
                                   src={icoutlineuser}
                                    height="100px"
                                  alt="MapMyClasses"/>
                                     <span className="justify-content-center align-items-center">
                                    <MDBCardTitle className='m-2'>Students Enrolled</MDBCardTitle>
                                    <MDBCardText style={{fontSize: "50px"}}>{studentCount[0].totalStudentCount}</MDBCardText>
                                    </span>
                                    </div>
                                </MDBCard>
                            <MDBCard className='mt-4 mb-3 p-2 ms-5 col-12 col-md-3'>
                                    <div className="d-flex flex-coloumn">
                                  <img className="m-2"
                                   src={studentsinteraction}
                                    height="100px"
                                  alt="MapMyClasses"/>
                                     <span className="justify-content-center align-items-center">
                                    <MDBCardTitle className='m-2'>Students Interactions</MDBCardTitle>
                                    <MDBCardText style={{fontSize: "50px"}}>40</MDBCardText>
                                    </span>
                                    </div>
                                </MDBCard>
                        </div>
                    </div>
                    <div className="m-5">
                        <MDBCard>
                    <MDBChart className="m-5" style={{height:"50px"}}
      type='bar'
      data={{
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
        datasets: [
          {
            label: 'My Courses',
            data: [5,10,15,4,8,9,19,12],
          },
          {
            label: 'Students Enrolled',
            data: [12,8,18,5,10,15,19,25],
            backgroundColor: '#94DFD7',
            borderColor: '#94DFD7',
          },
        ],
      }}
      options={{
        scales: {
          y: {
            stacked: false,
            ticks: {
              beginAtZero: true,
            },
          },
          x: {
            stacked: false,
          },
        },
      }}
    />
    </MDBCard>
    </div>
                </div>
            </>
        );
    else
        return <LoadingScreen/>;
}

export default KPICard;