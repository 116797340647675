const CourseDate = (props) => {
    const date = new Date(props.date);
    const months = {
        0: "January",
        1: "Feburary",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December"
    }

    const suffix = (date) => {
        if (parseInt(date / 10) === 1) {
            return 'th'
        }
        else {
            if (date % 10 === 1) {
                return 'st'
            }
            else if (date % 10 === 2) {
                return 'nd'
            }
            else if (date % 10 === 1) {
                return 'rd'
            }
            else
                return 'th'
        }
    }
    // return (date.toDateString())
    return (`${date.getDate()}${suffix(date.getDate())} ${months[date.getMonth()]} ${date.getFullYear()}`)
}

export default CourseDate;