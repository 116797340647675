import React, {useState} from "react";
import dashboardactive from "../../assets/dashboard-active.png";
import createcourseactive from "../../assets/create-course-active.png";
import announcement from "../../assets/communcations-icon.png";
import {
  MDBNavbarNav,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBIcon,
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";

function Tabs() {
  const [showNavNoTogglerThird, setShowNavNoTogglerThird] = useState(false);

    return (
            <>
                <div>
                
                <MDBNavbarToggler
          type="button"
          data-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowNavNoTogglerThird(!showNavNoTogglerThird)}
        >
           <MDBIcon fas icon="align-justify" />
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showNavNoTogglerThird}>     
            <MDBNavbarNav>
            <MDBNavbarLink
                    className="m-3 text-primary"
                    href="/#/dashboard"
                    >
                      <img className="m-2"
                src={dashboardactive}
                width="15px"
                height="15px"
                alt="MapMyClasses"/>
                    Dashboard
                  </MDBNavbarLink>
                  <MDBNavbarLink
                      className="m-3 text-primary"
                      href="/#/create-course"
                    >
                      <img className="m-2"
                src={createcourseactive}
                width="15px"
                height="15px"
                alt="MapMyClasses"/>
                      Create Course
                    </MDBNavbarLink>
                    <MDBNavbarLink
                      className="m-3 text-primary"
                      href="/#/announcement"
                    >
                      <img className="m-2"
                src={announcement}
                width="15px"
                height="15px"
                alt="MapMyClasses"/>
                      Announcement
                    </MDBNavbarLink>
            </MDBNavbarNav>
            </MDBCollapse> 
            </div>
            </>
        );
}

export default Tabs;