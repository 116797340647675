import React, {useState, useEffect} from "react";
import {
    MDBScrollbar,
    MDBAccordionItem,
    MDBSelect,
    MDBBtn
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import SupplemantaryMaterial from "./SupplementaryMaterial"


const SupplemantarySection = ({supplementaryMaterialId, course,courseTitle, courseId, addSupplementaryMaterial}) => {
    const [spmNo, setSpmNo] = useState(1);
    const [supplementaryMaterial, setSupplementaryMaterial] = useState([{}]);
    const [sections, setSections] = useState([{text: 'Select Section'}]);
    const [lectures, setLectures] = useState([{text: 'Select Lecture'}]);
    const [sectionId, setSectionId] = useState('');
    const [lectureId, setLectureId] = useState('');

    const updateSupplementaryMaterial = (material, id) => {
        let newState = [...supplementaryMaterial];
        newState[id] = material;
        setSupplementaryMaterial(newState);
        addSupplementaryMaterial({
            courseId: courseId,
            lectureId: lectureId,
            supplementaryMaterial: newState
        }, supplementaryMaterialId - 1);
    }

    useEffect(() => {
        if ((typeof course !== 'undefined') && (course !== null))
            if (Object.keys(course).length > 0) {
                let temp = course.sections.map(a => {return {text: a.sectionName, value: a._id}})
                temp.splice(0, 0, {text: 'Select Section'})
                setSections(temp);
            }
    }, [course, courseId])

    useEffect(() => {
        if ((typeof course !== 'undefined') && (course !== null))
            if ((Object.keys(course).length > 0) && (sectionId !== '')) {
                let temp = course.sections.filter(a => a._id === sectionId)[0].lectures.map(a => {
                    return {text: a.lectureTitle, value: a._id}
                })
                temp.splice(0, 0, {text: 'Select Lecture'})
                setLectures(temp);
            }
    }, [course, courseId, sectionId])



    return (
            <MDBAccordionItem collapseId={supplementaryMaterialId} headerTitle={`Supplementary Material ${supplementaryMaterialId}`} className={"my-2"}>
                <MDBScrollbar suppressScrollX style={{height: window.innerHeight}}>
                    <div className="d-flex flex-column align-items-center"
                         style={{borderBottom: "solid 1px rgba(0,0,0,0.1)"}}>
                        <div className="d-flex flex-column justify-content-start my-2 col-md-9 col-10">
                            <h4 className="d-flex align-items-center my-0 me-4">
                                Supplementary Material {supplementaryMaterialId}
                            </h4>
                            <div className="d-flex">
                                <div className="col-12 col-md-5 d-flex flex-column p-3">
                                    <MDBSelect
                                        data={sections} required
                                        placeholder={"Select Section"}
                                        onValueChange={(e) => setSectionId(e.value)}
                                    />
                                </div>
                                <div className="col-12 col-md-5 d-flex flex-column p-3">
                                    <MDBSelect
                                        data={lectures} required
                                        placeholder={"Select Lecture"}
                                        onValueChange={(e) => setLectureId(e.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column my-2 col-md-9 col-10">
                            {
                                [...Array(spmNo)].map((el, index) => (
                                    <SupplemantaryMaterial
                                        key={index} spmNo={index + 1} addSupplementaryMaterial={updateSupplementaryMaterial}
                                        lectureId={lectureId} courseTitle={courseTitle}/>
                                ))
                            }
                        </div>
                        <div className="col-md-9 col-10 my-2 mb-3">
                            <MDBBtn
                                color="primary" onClick={() => {
                                    setSupplementaryMaterial([...supplementaryMaterial, {}]);
                                    setSpmNo(spmNo + 1);
                                }}
                            >
                                Add Supplementary Material
                            </MDBBtn>
                        </div>
                    </div>
                </MDBScrollbar>
            </MDBAccordionItem>
    );
}

export default SupplemantarySection;