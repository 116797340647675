import React, {useState, useEffect} from "react";
import Questions from "./Questions"
import {
    MDBScrollbar,
    MDBAccordionItem,
    MDBSelect,
    MDBBtn
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";


const QuestionSection = ({lectureQuestionId, course, courseId, addLectureQuestions}) => {
    const [questionNo, setQuestionNo] = useState(1);
    const [questions, setQuestions] = useState([{}]);
    const [sections, setSections] = useState([{text: 'Select Section'}]);
    const [lectures, setLectures] = useState([{text: 'Select Lecture'}]);
    const [sectionId, setSectionId] = useState('');
    const [lectureId, setLectureId] = useState('');

    const updateQuestions = (question, id) => {
        let newState = [...questions];
        newState[id] = question;
        setQuestions(newState);
        addLectureQuestions({
            courseId: courseId,
            lectureId: lectureId,
            questions: newState
        }, lectureQuestionId - 1);
    }

    useEffect(() => {
        if ((typeof course !== 'undefined') && (course !== null))
            if (Object.keys(course).length > 0) {
                let temp = course.sections.map(a => {return {text: a.sectionName, value: a._id}})
                temp.splice(0, 0, {text: 'Select Section'})
                setSections(temp);
            }
    }, [course, courseId])

    useEffect(() => {
        if ((typeof course !== 'undefined') && (course !== null))
            if ((Object.keys(course).length > 0) && (sectionId !== '')) {
                let temp = course.sections.filter(a => a._id === sectionId)[0].lectures.map(a => {
                    return {text: a.lectureTitle, value: a._id}
                })
                temp.splice(0, 0, {text: 'Select Lecture'})
                setLectures(temp);
            }
    }, [course, courseId, sectionId])

    return (
            <MDBAccordionItem collapseId={lectureQuestionId} headerTitle={`Lecture Questions ${lectureQuestionId}`} className={"my-2"}>
                <MDBScrollbar suppressScrollX style={{height: window.innerHeight}}>
                    <div className="d-flex flex-column align-items-center"
                         style={{borderBottom: "solid 1px rgba(0,0,0,0.1)"}}>
                        <div className="d-flex flex-column justify-content-start my-2 col-md-9 col-10">
                            <h4 className="d-flex align-items-center my-0 me-4">
                                Lecture Questions {lectureQuestionId}
                            </h4>
                            <div className="d-flex">
                                <div className="col-12 col-md-5 d-flex flex-column p-3">
                                    <MDBSelect
                                        data={sections} required
                                        placeholder={"Select Section"}
                                        onValueChange={(e) => setSectionId(e.value)}
                                    />
                                </div>
                                <div className="col-12 col-md-5 d-flex flex-column p-3">
                                    <MDBSelect
                                        data={lectures} required
                                        placeholder={"Select Lecture"}
                                        onValueChange={(e) => setLectureId(e.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column my-2 col-md-9 col-10">
                            {
                                [...Array(questionNo)].map((el, index) => (
                                    <Questions
                                        key={index} questionNo={index + 1} addQuestions={updateQuestions}
                                        lectureId={lectureId}/>
                                ))
                            }
                        </div>
                        <div className="col-md-9 col-10 my-2 mb-3">
                            <MDBBtn
                                color="primary" onClick={() => {
                                    setQuestions([...questions, {}]); setQuestionNo(questionNo + 1);
                                }}
                            >
                                Add Question
                            </MDBBtn>
                        </div>
                    </div>
                </MDBScrollbar>
            </MDBAccordionItem>
    );
}

export default QuestionSection;