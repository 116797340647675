import { MDBCarousel,MDBCarouselItem,MDBCarouselInner,MDBCarouselElement, MDBContainer,MDBCarouselCaption} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import React from "react";
import image3 from "../../assets/slide3.jpg"


const Carousel= () => {
    return (
      <MDBContainer>
        <MDBCarousel fade id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                <MDBCarouselInner>
                    <MDBCarouselItem className='w-90 active' style={{maxHeight:"300px"}}  itemId={1}>
                        <MDBCarouselElement src="https://mdbootstrap.com/img/Photos/Slides/img%20(15).jpg" alt=""/>
                        <MDBCarouselCaption style={{fontSize:"30px"}}>“Learning never exhausts the mind.”<br></br>―Leonardo Da Vinci</MDBCarouselCaption>
                    </MDBCarouselItem>

                    <MDBCarouselItem className='w-90 'style={{maxHeight:"300px"}}  itemId={2}>
                        <MDBCarouselElement src="https://mdbootstrap.com/img/Photos/Slides/img%20(22).jpg" alt=""/>
                        <MDBCarouselCaption style={{fontSize:"30px"}}>“Live as if you were to die tomorrow. Learn as if you were to live forever.”
                       <br></br>― Mahatma Gandhi</MDBCarouselCaption>
                    </MDBCarouselItem>

                        <MDBCarouselItem className='w-90 'style={{maxHeight:"300px"}}  itemId={3}>
                       <MDBCarouselElement src="https://mdbootstrap.com/img/Photos/Slides/img%20(23).jpg" alt=""/>
                       <MDBCarouselCaption style={{fontSize:"30px"}}>“In learning you will teach, and in teaching you will learn.”
                       <br></br>― Phil Collins</MDBCarouselCaption>
                   </MDBCarouselItem>
                    {/* <a class="carousel-control-prev" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next"  role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a> */}
               </MDBCarouselInner>
            </MDBCarousel>
            </MDBContainer>

    );
};

export default Carousel;