import React from 'react';
import ReactDOM from 'react-dom/client';
import {HashRouter} from "react-router-dom";
import './index.css';
import '@krtrimaiq-cognitive-solutions/mdb-react-ui-kit/dist/css/mdb.min.css'
import '@krtrimaiq-cognitive-solutions/mdb-react-file-upload/dist/css/file-upload.min.css';
import '@krtrimaiq-cognitive-solutions/mdb-react-multi-carousel/dist/css/multi-carousel.min.css';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HashRouter basename="/">
        <App />
    </HashRouter>
);

