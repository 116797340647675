import React, {useState} from "react";
import Lecture from "./Lecture";
import {
    MDBInput,
    MDBAccordion,
    MDBScrollbar,
    MDBAccordionItem,
    MDBBtn
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";


const Section = ({id, courseId, addSection, courseTitle}) => {
    const sectionId = id;
    const [lectureNo, setLectureNo] = useState(1);
    const [sectionName, setSectionName] = useState("");
    const [lectures, setLectures] = useState([{}]);

    const updateLectures = (lecture, id) => {
        let newState = [...lectures];
        newState[id] = lecture;
        setLectures(newState);
        addSection({
            'sectionName': sectionName,
            "_id": `${courseId}_${sectionId}`,
            "lectures": newState
        }, sectionId - 1);
    }

    return (
        <MDBAccordion initialActive={0}>
              <MDBScrollbar suppressScrollX >
            <MDBAccordionItem collapseId={sectionId} headerTitle={`Section ${sectionId}:`}>
                    <div className="d-flex flex-column align-items-center"
                         style={{borderBottom: "solid 1px rgba(0,0,0,0.1)"}}>
                        <div className="d-flex justify-content-start my-2 col-md-9 col-10">
                            <h4 className="d-flex align-items-center my-0 me-4">
                                Section {sectionId}:
                            </h4>
                            <MDBInput
                                label='Section Name' type='text' required
                                onChange={(e) => setSectionName(e.target.value)}
                            />
                        </div>
                        <div className="d-flex flex-column my-2 col-md-9 col-10">
                            {
                                [...Array(lectureNo)].map((el, index) => (
                                    <Lecture
                                        key={index} id={index + 1} addLecture={updateLectures} courseTitle={courseTitle}
                                        sectionId={`${courseId}_${sectionId}`}/>
                                ))
                            }
                        </div>
                        <div className="col-md-9 col-10 my-2 mb-3">
                            <MDBBtn
                                color="light" rounded className={"text-primary"}
                                onClick={() => {setLectures([...lectures, {}]); setLectureNo(lectureNo + 1);}}
                            >
                                Add Lecture
                            </MDBBtn>
                        </div>
                    </div>
            </MDBAccordionItem>
            </MDBScrollbar>
        </MDBAccordion>
    );
}

export default Section;