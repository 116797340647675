import React, { useState, useContext } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
  MDBIcon,
  MDBAutocomplete
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import logo from "../../assets/logo.png";
import Login from "./Login";
import { useMDBCookies } from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";
import { useNavigate } from "react-router-dom";
import { UrlContext } from "../../App"
import loginlogo from "../../assets/login-logo.png";
import dashboardactive from "../../assets/dashboard-active.png";
import searchicon from "../../assets/search-icon.png";
import Tabs from "./Tabs";

function Header() {
  const [showNavNoTogglerThird, setShowNavNoTogglerThird] = useState(false);
  const [visible, setVisible] = useState(true);
  const [userId, { removeItem }] = useMDBCookies("user-id", "");
  const [userType, { removeItem: removeUserType }] = useMDBCookies(
    "user-type",
    ""
  );
  const [userFirstName, { removeItem: removeUserFirstName }] = useMDBCookies(
    "user-firstname",
    ""
  );
  const [userLastName, { removeItem: removeUserLastName }] = useMDBCookies(
    "user-lastname",
    ""
  );
  const openModalHandler = () => setLogin(true);
  const { isLogin, setLogin } = useContext(UrlContext);
  const [searchWord,setSearchWord]= useState("");

  const navigation = useNavigate();

  function handleLogout() {
    removeItem();
    removeUserFirstName();
    removeUserLastName();
    removeUserType();
    navigation("/");
  }
  const getCourse = (id) => {
    navigation(`/about-course/${id}`)
  };

  return (
    <>
      <MDBNavbar
        expand="lg"
        sticky
        style={{ backgroundColor: "white" }}
        className={"d-flex align-items-center"}
      >
        <MDBNavbarBrand href="/">
          <div className="d-flex mb-1 mt-1 px-5">
            <div className="flex-shrink-0 ">
              <img
                src={logo}
                width="160rem"
                height="80rem"
                className="rounded d-block img-fluid"
                alt="MapMyClasses"
              />
            </div>
          </div>
        </MDBNavbarBrand>
        <MDBNavbarToggler
          type="button"
          data-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowNavNoTogglerThird(!showNavNoTogglerThird)}
        >
          <MDBIcon fas icon="align-justify" />
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showNavNoTogglerThird}>
          <MDBNavbarNav right fullWidth={false} className="mb-1 mb-lg-0 mt-1">
            <MDBNavbarItem>
              {!userId && (
                <MDBNavbarLink className="m-3 text-primary" onClick={openModalHandler} style={{color:"#111B58",cursor:"pointer"}}>
                 <img className="m-2"
                src={loginlogo}
                width="15px"
                height="15px"
                alt="MapMyClasses"
              />
                    Login
                </MDBNavbarLink>
              )}
            </MDBNavbarItem>
            {userId && (
              <>
                <MDBNavbarItem>
                  <div className="input-group rounded m-3">
                    <MDBAutocomplete
                      label="Search"
                      displayValue={(value) => value.courseTitle}
                      dataFilter={async (query) => {
                        const url = `https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/course-search?searchWord=${encodeURI(
                          query
                        )}`;
                        const response = await fetch(url);
                        const data = await response.json();
                        let courseArray = [];
                        setSearchWord(query);
                        for (var i = 0; i < data.length; i++) {
                          courseArray.push({
                            courseTitle: data[i].courseTitle,
                            id: data[i]["_id"]["$oid"],
                          });
                        }
                        courseArray.push({"courseTitle":query,"id":0})
                        return courseArray;
                      }}
                      onSelect={(e) => {
                        if (searchWord === e.courseTitle)
                          navigation(`/search-result/${searchWord}`);
                        else
                          getCourse(e.id);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          if (searchWord.length > 3) {
                            navigation(`/search-result/${searchWord}`);
                          }
                        }
                      }}
                    />
                    <span>
                      <img className="m-2"
                  src={searchicon}
                  width="15px"
                  height="15px"
                  alt="MapMyClasses" />
                    </span>
                  </div>
                </MDBNavbarItem>
                 <MDBNavbarItem>
                 {userType !== "instructor" && (
                  <MDBNavbarLink
                    className="m-3 text-primary"
                    href="/#/dashboard">
                      <img className="m-2"
                src={dashboardactive}
                width="15px"
                height="15px"
                alt="MapMyClasses"/>
                    
                    Dashboard
                  </MDBNavbarLink>
                 )}
                </MDBNavbarItem>
                <MDBNavbarItem>
                        <MDBNavbarLink
                        className="m-3 text-primary"
                          href="/#/profile"
                          style={{ color: "#111B58" }}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              navigation("/profile");
                            }
                          }}
                        >
                          <MDBIcon className="px-1 pt-1" fas icon="user-alt" />
                          {userFirstName} {userLastName}
                  </MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                        <MDBNavbarLink
                        className="m-3 text-primary"
                          onClick={handleLogout}
                          style={{ color: "#111B58",cursor:"pointer"}}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              handleLogout();
                            }
                          }}
                        >
                          <MDBIcon className="px-3" fas icon="sign-out-alt" />
                          Logout
                        </MDBNavbarLink>
                      </MDBNavbarItem>
                {/* <MDBNavbarItem>
                  <MDBDropdown>
                    <MDBDropdownToggle style={{cursor:"pointer"}}
                      tag="a"
                      className="nav-link m-3 text-primary"
                    >
                      <MDBIcon className="px-1 pt-1" fas icon="user-alt" />
                      {userFirstName} {userLastName}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="px-1 pt-3" >
                      <MDBDropdownItem >
                        <MDBDropdownLink
                          href="/#/profile"
                          style={{ color: "#111B58" }}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              navigation("/profile");
                            }
                          }}
                        >
                          <img className="m-2"
                src={profile}
                width="15px"
                height="15px"
                alt="MapMyClasses"/>
                          Profile
                        </MDBDropdownLink>
                      </MDBDropdownItem>
                      <MDBDropdownItem>
                        <MDBDropdownLink
                          onClick={handleLogout}
                          style={{ color: "#111B58" }}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              handleLogout();
                            }
                          }}
                        >
                          <MDBIcon className="px-3" fas icon="sign-out-alt" />
                          Logout
                        </MDBDropdownLink>
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown> 
                </MDBNavbarItem>*/}
              </>
            )}
          </MDBNavbarNav>
        </MDBCollapse>
       
      </MDBNavbar>
      {userType === "instructor" && (
       <MDBNavbar expand='lg'
       style={{ backgroundColor: "white",height:"65px"}}
        className={"d-flex align-items-center"} sticky>
        <Tabs/></MDBNavbar>
      )}
      <Login login={isLogin} />

    </>
  );
}

export default Header;
