import React from 'react';
import {CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


function SectionProgressbar(props) {
    const percentage = props.percentage;

    return (
        <div className="circular-progressbar">
            <div style={{width: 25}}>
                <CircularProgressbar style={{color: "#111B58"}} value={percentage} text={`${percentage}%`}/>
            </div>
        </div>
    );
}

export default SectionProgressbar;