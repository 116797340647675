import React, {useContext, useState} from "react";
import {
    MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRadio,
    MDBModalTitle
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import {UrlContext} from "../../App"
import axios from "axios";
import {useMDBCookies} from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";
import {useParams} from "react-router-dom";


function QuestionModal({id, setQuestionId, questions, lectureId, lectureDuration}) {
    const [userId] = useMDBCookies("user-id", "");
    const {courseId} = useParams();
    const [answer, setAnswer] = useState("");
    const [message, setMessage] = useState("");
    const {questionModal, setQuestionModal, playerRef} = useContext(UrlContext);

    const ValidateAnswer = () => {
        let score = 0;

        if (answer === questions[id].answer) {
            score=1;
            axios.post(
                "https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/update-progress", 
                JSON.stringify({
                    "userId": userId,
                    "courseId": courseId,
                    "lectureId": lectureId,
                    "completedDuration": questions[id].duration
                })
                )
                .then(function (response) {
                    setQuestionModal(false);
                    playerRef.current.play();
                    setQuestionId(id + 1);
                    setMessage("");
                });
        }
        else {
            setMessage("Incorrect answer. Please try again.");
            setQuestionModal(false);
            if (id !== 0)
                playerRef.current.currentTime = questions[id - 1].duration;
            else
                playerRef.current.currentTime = 0;
            playerRef.current.play();
        }
        axios.post(
            "https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/continuous-evaluation",
            JSON.stringify({
                "userId": userId,
                "courseId": courseId,
                "lectureId": lectureId,
                "questionId":questions[id]._id,
                "score": score,
                "userAnswer": answer
            })
        )
        .then(response=>{
            document.querySelector('input:checked').checked = false;
            setMessage("");
        })
       
    };

    if (id < questions.length)
        return (
            <div>
                <MDBModal staticBackdrop tabIndex='-1' show={questionModal}>
                    <MDBModalDialog centered>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>
                                    Question {id+1}
                                </MDBModalTitle>
                            </MDBModalHeader>
                            <MDBModalBody className={"d-flex flex-column"}>
                                <div className={"text-danger"}>
                                    {message}
                                </div>
                                <div className={"d-flex flex-column align-items-start"}>
                                    <h5 className="my-3 font-bold">
                                        {questions[id].question}
                                    </h5>
                                    {questions[id].options.map((option, index) => (
                                        <div key={index} className="m-2 text-center">
                                            <MDBRadio key={index} name={`question-${id}`} value={option}
                                                      id={`question-${id}-option-${index}`} label={option}
                                                      onChange={(e) => setAnswer(e.target.value)} />
                                        </div>
                                    ))}
                                </div>
                            </MDBModalBody>

                            <MDBModalFooter className="justify-content-center">
                                <MDBBtn variant="primary" active className="m-3" onClick={ValidateAnswer}>
                                    Submit
                                </MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            </div>
        );
}


export default QuestionModal;