import React, {useState,useEffect} from "react";
import {MDBRating, MDBRatingElement} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import axios from "axios";
import {useMDBCookies} from '@krtrimaiq-cognitive-solutions/mdb-react-cookies-management';

const Ratings = (props) => {
    const className = props.className;
    const iconColor = "#111B58";
    const readonly = props.readonly;
    const small = props.small;
    const [userId] = useMDBCookies('user-id', '');
    const courseId = props.courseId;
    const style = props.style;

    const [rating, setRating] = useState(props.rating);
    // This will launch only if propName value has chaged.
    useEffect(() => {
        setRating(props.rating)
    }, [props.rating]);
    const addRating = (value)=>
    {
        if ((!readonly) && (value !== 0)) {
            let ratingData = {
                userId: userId,
                courseId: courseId,
                rating: value
            };
            axios
                .post(
                    "https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/add-rating",
                    JSON.stringify(ratingData)
                )
        }
    }
    let iconClassName = "px-1";
    if (small)
        iconClassName = "px-0"
    if(rating!==0 || rating!==null)
    {
        return (
            <MDBRating className={className} defaultValue={rating} onChange={(value) => addRating(value)}
                        readonly={readonly} style={style}>
                <MDBRatingElement itemId={1} icon="star" color={iconColor} iconClassName={iconClassName}/>
                <MDBRatingElement itemId={2} icon="star" color={iconColor} iconClassName={iconClassName}/>
                <MDBRatingElement itemId={3} icon="star" color={iconColor} iconClassName={iconClassName}/>
                <MDBRatingElement itemId={4} icon="star" color={iconColor} iconClassName={iconClassName}/>
                <MDBRatingElement itemId={5} icon="star" color={iconColor} iconClassName={iconClassName}/>
            </MDBRating>
        );
    }
    else
    {
        return(
            <div className={"mt-2 mr-2"} style={{fontSize: "8px", textAlign: "start"}}>
            Course Ratings Not Available
        </div>
        )
    }
};
export default Ratings;
