export const CourseStructureData = [
    {
        id: 1,
        title: "Start with your goals.",
        content:
            "Setting goals for what to learners will accomplish in your course (also known as learning objectives) at the beginning will help you determine what contents to include in your course and how you will tech the content to help your learners acheive the goals.",
    },
    {
        id: 2,
        title: "Create an Outline.",
        content:
            "Decide what skills you'll teach and how you'll teach them.Group related lectures into sections.Each section should have atleast 3 lectures and include at least one assignment or practical activity.",
    },
    {
        id: 3,
        title: "Introduce yourself and create momentum.",
        content:
            "  People online want to start leaarning quickly. Make an introduction section that gives learners something to be excited about in the first 10 minutes.",
    },
    {
        id: 4,
        title: "Sections have a clear learning objective.",
        content:
            "  Introduce each section by describing the section's  goal and why it;s important.Give leactures and sections titles that reflect their content and have a logical flow.",
    },
    {
        id: 5,
        title: "Lectures cover one concept.",
        content:
            " A good lecture length is 2-7 minutes to keep students interested and help them study in short bursts.Cover a single topic in each lecture so learners can easily find and re-watch them later.",
    },
    {
        id: 6,
        title: "Mix and match your lecture types.",
        content:
            "Alternate between filming yourself,your screen and your slides or other visuals.Showing yourself can help learners feel connected.",
    },
    {
        id: 7,
        title: "Practice activities create hands-on learning",
        content:
            "Help learners apply your leassons to their real world with projects,assignments, coding excercises or worksheets.",
    }
];

export default CourseStructureData;
