import React,{useState,useEffect} from "react";
import  {MDBTableEditor} from "@krtrimaiq-cognitive-solutions/mdb-react-table-editor";
import { MDBBtn} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import axios from "axios";
import Header from "../utils/Header";
import AdminDasboardNav from "./AdminDashboardNav";
import AddUser from "./AddUser";

function ListofInstructor() {

  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [profile, setProfile] = useState({});
  const [modal, setModal] = useState(false);

    const [asyncData, setAsyncData] = useState({
        columns: [
            { label: 'User Id', field: '_id'},
            { label: 'Instructor FirstName', field: 'firstName'},
            { label: 'Instructor LastName', field: 'lastName'},
            { label:'Email', field:'emailId'},
            {label:'Biography',field:'biography'},
            {label:'Address',field:'address'},
            {label:'City',field:'city'},
            {label:'Phone Number',field:'phoneNumber'},
            {label:'Pincode',field:'pincode'},
            {label:'Created Courses',field:'createdCourses'}
        ],
        rows: [],
    });

    useEffect(() => {
        if (loading) {
            axios.get('https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/get-instructordata')
                .then((response) => 
                setTimeout(() => {
                        setAsyncData({
                            columns: asyncData.columns,

                            rows: response.data.instructorData.map((user) => ({
                                ...user,
                                firstName:`${user.firstName}`,
                                lastName:`${user.lastName}`,
                                _id: user['_id']['$oid'],
                                createdCourses:
                                 user.createdCourses.map(course => 
                                    course.courseTitle
                                )
                            })),
                        });
                        
                    }, 3000)
                );
            }
    }, [loading, asyncData.columns]);
  

    useEffect(() => {
        // if (asyncData.rows.length === 0) {
        //     setLoading(true);
        // } else {
        //     setLoading(false);
        // }
        setLoading(false);
    }, [asyncData.rows]);

   function deleteInstructorProfile (id) {
        axios.post(
            "https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/delete-user",
            JSON.stringify({userId: id})
            )
            .then((response) => setLoading(true));
    }
    
    // const addUser=()=>{
    //   setModal(true)
    // }
    function addUser (data) {
         axios.post(
             "https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/create-user",
             JSON.stringify({
                firstName: data.firstName,
                lastName:data.lastName,
                emailId:data.emailId,
                password:"test@123",
                biography:data.biography,
                address:data.address,
                city:data.city,
                phoneNumber:data.phoneNumber,
                pincode:data.pincode,
                userType:"instructor"
            })
             )
             .then((response) => {setLoading(true);console.log(response)});
    }
    
    const editProfile = ({...profile}) => {
        setSubmit(true);
        axios.post(
            `https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/profile?userId=${profile._id}`,
            JSON.stringify({
                userId: profile._id,
                emailId: profile.emailId,
                firstName: profile.firstName,
                lastName: profile.lastName,
                biography: profile.biography,
                address:profile.address,
                city: profile.city,
                phoneNumber: profile.phoneNumber,
                pincode:profile.pincode
            })
        )
            .then((response) => {
                setLoading(true)
                setProfile(response.data);
            })

    };

return (
    <>
      <div>
      <Header />
        <AdminDasboardNav />
      <div  style={{ width: "100%", height: "100%", paddingLeft: "25%" }}>
        <div className="pt-3 ps-4 pe-4 pb-4">
        <div className="card p-4">
        
        <MDBTableEditor confirmDelete
                mode='modal'
                isLoading={loading}
                data={asyncData}
                entriesOptions={[10,20,30,40]}
                entries={10}
                onAdd={(newRow) => {addUser( newRow)}}
                onModify={(modifiedData,j) => {editProfile( modifiedData[j])}}
                onDelete={(e) => {deleteInstructorProfile(asyncData.rows[e]["_id"])}}
            />
        </div>
        {/* <div className="m-4">
                <MDBBtn color="primary" onClick={() => addUser()} rounded>
                    Add Instructor
                </MDBBtn>
            </div> */}
        </div>
        
        </div>
      </div>
      {/* <AddUser modal={modal} setModal={setModal}/> */}
    </>
  );
}

export default ListofInstructor;