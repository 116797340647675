import React from 'react';
import {MDBAccordion,MDBAccordionItem,MDBSideNavLink
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";

const SearchList = () => {

  return (
      <div className='m-2'>
      <MDBAccordion initialActive={0}>
  <MDBAccordionItem collapseId={1} headerTitle='Sort by'>
  <MDBSideNavLink>Hacking 101</MDBSideNavLink> 
  <MDBSideNavLink>Linux Fundamentals</MDBSideNavLink>
  <MDBSideNavLink>Productivity Masterclass</MDBSideNavLink>
  </MDBAccordionItem>
  <MDBAccordionItem collapseId={2} headerTitle='Related Courses'>
  <MDBSideNavLink>Hacking 101</MDBSideNavLink> 
  <MDBSideNavLink>Linux Fundamentals</MDBSideNavLink>
  <MDBSideNavLink>Productivity Masterclass</MDBSideNavLink> 
  </MDBAccordionItem>
  <MDBAccordionItem collapseId={3} headerTitle='Popular Courses'>
  <MDBSideNavLink>Hacking 101</MDBSideNavLink> 
  <MDBSideNavLink>Linux Fundamentals</MDBSideNavLink>
  <MDBSideNavLink>React JS</MDBSideNavLink>
  <MDBSideNavLink>SQL</MDBSideNavLink>
  <MDBSideNavLink>Productivity Masterclass</MDBSideNavLink>
  </MDBAccordionItem>
</MDBAccordion>
    </div>
);
};

export default SearchList;
