import React, {useState, useEffect} from "react";
import SupplemantarySection from "./SupplementarySection";
import {MDBAccordion, MDBBtn,MDBScrollbar} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import axios from "axios";
import {useMDBCookies} from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";


function  AddSupplementaryMaterial({courseId, save, setSave,setPreview}) {
    const [supplementaryMaterialId, setSupplementaryMaterialId] = useState(1);
    const [course, setCourse] = useState([]);
    const [userId] = useMDBCookies('user-id', '');
    const [supplementaryMaterial, setSupplementaryMaterial] = useState([{sectionId: "", lectureId: "", supplementaryMaterial: []}]);
    const [courseTitle, setCourseTitle] = useState("");

    useEffect(() => {
        axios.get(`https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/course?courseId=${courseId}&userId=${userId}`)
            .then((response) => {
                setCourse(response["data"]);
                setCourseTitle(response["data"]["courseTitle"]);
            });
    }, [userId, courseId, save]);


    const updateSupplementaryMaterial = (material, id) => {
        let newState = supplementaryMaterial;
        newState[id] = material;
        setSupplementaryMaterial(newState);
    };

    const createSupplementaryMaterial = () => {
        supplementaryMaterial.forEach(material => {
            axios.post("https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/supplementary-material", material )
            .then(response => {setSave(true);setPreview(true)});
        })
    };

    return (
        <>
          <MDBScrollbar suppressScrollX style={{height: window.innerHeight}}>
            <h3 className="my-4">
                Add Supplementary Material
            </h3>
            <MDBAccordion initialActive={"1"}>
                {[...Array(supplementaryMaterialId)].map((el, index) => (
                    <SupplemantarySection
                        key={index}
                        supplementaryMaterialId={index + 1}
                        addSupplementaryMaterial={updateSupplementaryMaterial}
                        course={course}
                        courseId={courseId}
                        courseTitle={courseTitle}
                    />
                ))}
            </MDBAccordion>

            <div className="justify-content-center py-3">
                <MDBBtn
                    color="light" rounded className={"text-primary"}
                    onClick={() => {
                        setSupplementaryMaterialId(supplementaryMaterialId + 1);
                        setSupplementaryMaterial([...supplementaryMaterial, {}]);
                    }}
                >
                    Add SupplementaryMaterial to another Lecture
                </MDBBtn>
            </div>
            <div className="m-4">
                <MDBBtn color="primary" onClick={() => createSupplementaryMaterial()} disabled={save} rounded>
                    Save
                </MDBBtn>
            </div>
            </MDBScrollbar>
        </>
    );
}

export default AddSupplementaryMaterial;

