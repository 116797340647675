import React, {useState, useEffect} from "react";
import SectionProgressbar from "../viewCourse/SectionProgressBar";
import {useMDBCookies} from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";

const SectionContentHeader = ({section, active, progress}) => {
    const [userId] = useMDBCookies('user-id', '');
    const [completedLectures, setCompletedLecture] = useState(0);

    useEffect(() => {
        section.lectures.forEach((lecture, i) => {
            if (lecture.completedDuration === lecture.lectureDuration) {
                setCompletedLecture(i + 1);
            }
        });
    }, [])

    const sectionProgress = parseInt((section.sectionCompletedDuration / section.sectionDuration) * 100);
    let color = 'black';
    if (active)
        color = '#1266f1'
    return (
        <div>
            <div className={"d-flex flex-row"} style={{color: color}}>
                {progress && (
                    typeof section.sectionCompletedDuration === "undefined" ?
                        <SectionProgressbar percentage={0}/> :
                        <div className={"mt-3"}>
                            <SectionProgressbar percentage={sectionProgress}/>
                        </div>
                )}
                <div className={"d-flex flex-row"}>
                    <div className={"text-truncate justify-content-top me-2"} style={{width: "fit-content", maxWidth: 175}}>
                        {section.sectionName}
                    </div>
                    <div className={"justify-content-end"} style={{paddingRight:"10px"}}>
                        {parseInt(section.sectionDuration / 60)}:{(section.sectionDuration % 60) > 9 ? (section.sectionDuration % 60) : "0" + (section.sectionDuration % 60)}
                   </div>
                </div>
            </div>
             {progress && (
                <div style={{fontSize: "12px",marginLeft:"42px"}}>
                    {completedLectures}/{section.lectures.length}
                </div>
             )}
        </div>
        )
}

export default SectionContentHeader;