import React from "react";
import { MDBProgress,  MDBProgressBar } from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";

const ProgressBar = ({progress, className, rounded, small}) => {
    let style = {};
    let height = 10;
    if (small)
        height = 5;
    if (rounded)
        style = {borderRadius: "10px"}
    return (
        <MDBProgress className={className} height={height} style={style}>
            <MDBProgressBar animated striped style={{backgroundColor: "#111B58"}} width={progress} />
        </MDBProgress>
    );
};
export default ProgressBar;
