import React, {useState, useEffect} from "react";
import {MDBInput, MDBSpinner, MDBTextArea} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import {MDBFileUpload} from "@krtrimaiq-cognitive-solutions/mdb-react-file-upload";
import axios from 'axios';

const SupplementaryMaterial = ({spmNo,courseTitle, lectureId, addSupplementaryMaterial}) => {
    const [materialTitle, setMaterialTitle] = useState("");
    const [url, setUrl] = useState("");
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        addSupplementaryMaterial(
            {
                "_id": `${lectureId}_${spmNo}`,
                "materialTitle": materialTitle,
                "materialUrl": url,
            },
            spmNo - 1
        );
    }, [lectureId, spmNo, materialTitle,  url])

    useEffect(() => {
        if ((typeof file !== 'undefined') && (file !== null)) {
            setUploading(true);
            let el = document.createElement('video');
            el.src = URL.createObjectURL(file);
            const data = {
                courseTitle: courseTitle,
                fileName: file.name
            };
            axios.post('https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/get-signed-url', JSON.stringify(data))
                .then(function (response) {
                    setUrl(response.data.objectUrl)
                    let reader = new FileReader()
                    const uploadUrl = response.data.uploadUrl;
                    reader.onloadend = (e) => {
                        const videoData = new Blob([new Uint8Array(e.target.result)]);
                        axios.put(uploadUrl, videoData)
                            .then(response => {
                                setUploading(false);
                            });
                    }
                    reader.readAsArrayBuffer(file)
                });
        }
    }, [file, courseTitle]);

    let spinner = "";
    if (uploading) {
        spinner = <div style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
                       className='mask w-100 d-flex align-items-center justify-content-center' tabIndex="-1">
            <MDBSpinner role='status' color={"primary"}>
                <span className='visually-hidden'>Loading...</span>
            </MDBSpinner>
        </div>
    }

    return (
        <div className="card d-flex flex-row p-4 my-3 justify-content-between">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-between">
                <h5 className="d-flex justify-content-start">
                    Material {spmNo}
                </h5>
                <MDBInput label='Material Title' type='text' className="w-100" required
                          onChange={(e) => setMaterialTitle(e.target.value)} />
            </div>
            <div className="col-12 col-md-5 bg-image">
                <MDBFileUpload style={{height: "300px"}} getInputFiles={(files) => setFile(files[0])} required/>
                {spinner}
            </div>
        </div>
    )
}

export default SupplementaryMaterial;