import React from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardText,
    MDBCardFooter
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import {Link} from "react-router-dom";
import Ratings from "./Ratings";
import instructor from "../../assets/instructor.jpg";

const CardGrid = ({courses}) => {
    return (
        <div>
            <div className='d-flex flex-wrap'>
                {courses.map((course) => (
                    <Link className="text-black d-flex clo-12 col-md-4 my-3" to={`/about-course/${course["_id"]["$oid"]}`}>
                        <MDBCard className='d-flex w-100 h-90 m-3' style={{color:"#111B58"}}>
                            <MDBCardImage
                                src={course.courseImgUrl}
                                style={{height: "180px", borderRadius: "4px"}}
                            />
                            <MDBCardBody>
                                <div style={{fontSize: "20px", fontWeight: 500, textAlign: "start"}}>
                                    {course.courseTitle}
                                </div>
                                <div style={{fontSize: "16px", textAlign: "start"}}>
                                    {course.courseDesc}
                                </div>
                            </MDBCardBody>
                            <MDBCardFooter className={"d-flex justify-content-between align-items-center"}>
                            <div>
                            <img
              src={instructor}
              class="rounded-circle"
              alt=""
              style={{width: "45px", height: "45px"}}
              />
                            </div>
                                <div>
                                    {course.courseInstructor.firstName} {course.courseInstructor.lastName}
                                </div>
                                <MDBCardText>
                                   <Ratings rating={course.averageRating} courseId={course['_id']['$oid']} readonly small />
                                </MDBCardText>
                            </MDBCardFooter>
                        </MDBCard>
                    </Link>
                ))}


            </div>
        </div>
    );
};

export default CardGrid;
