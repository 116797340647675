import React, {useState} from "react";
import axios from "axios";
import {
    MDBInput,
    MDBBtn,
    MDBSpinner,
    MDBInputGroup,
    MDBCard,
    MDBContainer,
    MDBTextArea,
    MDBCardBody,
    MDBScrollbar
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import {MDBFileUpload} from "@krtrimaiq-cognitive-solutions/mdb-react-file-upload";
import {useMDBCookies} from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";

function CourseDetails({save, setSave, setCourseId}) {
    const [userId] = useMDBCookies("user-id", "");
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [obj, setObj] = useState([]);
    const [tags, setTag] = useState([]);
    const [file, setFile] = useState({
        filename: "",
        image: null,
    });
    const [objCount, setObjCount] = useState(1);
    const [tagCount, setTagCount] = useState(1);
    const [uploading, setUploading] = useState(false);

    const createCourse = (e) => {
        setUploading(true)
        const data = {
            courseTitle: title,
            fileName: file.name
        };
        axios
            .post(
                "https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/get-signed-url",
                JSON.stringify(data)
            )
            .then((response) => {
                const url = response.data["objectUrl"];
                let reader = new FileReader()
                const uploadUrl = response.data.uploadUrl;
                reader.onloadend = (e) => {
                    const imgData = new Blob([new Uint8Array(e.target.result)]);
                    axios.put(uploadUrl, imgData).then((response) => {
                        axios
                            .post(
                                "https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/create-edit-course",
                                JSON.stringify({
                                    courseInstructor: userId,
                                    courseTitle: title,
                                    courseDesc: desc,
                                    courseObj: obj,
                                    courseImgUrl: url,
                                    tags: tags
                                })
                            )
                            .then(function (response) {
                                setCourseId(response.data['$oid'])
                                setSave(true)
                                setUploading(false)
                            });
                    });
                }
                reader.readAsArrayBuffer(file)
            });

    };

    const buttonText = () => {
        if (uploading) {
            return (
                <MDBSpinner color="light" size="sm">
                    <span className="visually-hidden">Loading...</span>
                </MDBSpinner>
            );
        } else return "Save";
    };

    const updateCourseObj = (objective, i) => {
        let courseObj = [...obj];
        courseObj[i] = objective;
        setObj(courseObj);
    };

    const updateCourseTag = (tag, i) => {
        if (tagCount < 10)
            setTagCount(i + 2);
        let courseTag = [...tags];
        courseTag[i] = tag;
        setTag(courseTag);
    };

    let spinner = "";
    if (uploading) {
        spinner = (
            <div
                style={{backgroundColor: "rgba(0, 0, 0, 0.6)", height: "300px"}}
                className="mask w-100 d-flex align-items-center justify-content-center"
                tabIndex="-1"
            >
                <MDBSpinner role="status" color={"primary"}>
                    <span className="visually-hidden">
                        Loading...
                    </span>
                </MDBSpinner>
            </div>
        );
    }

    return (
        <div>
            <MDBContainer>
                <MDBCard className="d-flex">
                    <MDBCardBody>
                        <MDBScrollbar suppressScrollX style={{height: window.innerHeight}}>
                        <h3 className="mx-4 text-start" style={{color: "navy"}}>
                            Course Information
                        </h3>
                        <h5 className="mx-5 my-3 text-start">
                            Enter Course Details
                        </h5>
                        <div className={"d-flex flex-column"}>
                            <div className="d-flex flex-row p-3 justify-content-between">
                                <div className="col-12 col-md-5 bg-image">
                                    <MDBFileUpload
                                        defaultMessage={"Drag an image or click to upload Course Image"}
                                        style={{height: "300px"}}
                                        getInputFiles={(files) => setFile(files[0])}
                                        acceptedExtensions="image/*"
                                        required
                                    />
                                    {spinner}
                                </div>
                                <div className="col-12 col-md-6 d-flex flex-column justify-content-between">
                                    <div>
                                        <h5 className="text-start">
                                            Course Title
                                        </h5>
                                        <MDBInput
                                            label="Course Title" type="text" value={title} required
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                        <h5 className="mt-5 text-start">
                                            Course Description
                                        </h5>
                                        <MDBTextArea
                                            label="Course Description" type="text" rows={2} value={desc} required
                                            onChange={(e) => setDesc(e.target.value)}
                                        />
                                        <h5 className="text-start mt-5">
                                            Course Objectives
                                        </h5>
                                        <MDBInputGroup className={"d-flex flex-column"}>
                                            {[...Array(objCount)].map((el, index) => (
                                                <input
                                                    className='form-control w-100 my-2' key={index} type='text' required
                                                    style={{borderRadius: "4px"}}
                                                    onChange={(e) => updateCourseObj(e.target.value, index)}
                                                />
                                            ))}
                                        </MDBInputGroup>
                                        <MDBBtn
                                            type="submit" color={"light"} rounded
                                            className={"d-flex justify-content-md-start justify-content-center my-2"}
                                            onClick={() => {setObjCount(objCount + 1); setObj([...obj, ""])}}
                                        >
                                            <div className={"text-primary"}>
                                                Add Course Objective
                                            </div>
                                        </MDBBtn>
                                        <h5 className="d-flex mt-5 justify-content-md-start justify-content-center">
                                            Course Tags
                                        </h5>
                                        <MDBInputGroup className={"d-flex flex-wrap"}>
                                            {[...Array(tagCount)].map((tag, i) => (
                                                <input
                                                    className='form-control my-2 me-2' key={i} type='text' required={i + 1 < tagCount}
                                                    style={{borderRadius: "4px", minWidth: "100px"}}
                                                    onChange={(e) => updateCourseTag(e.target.value, i)}
                                                />
                                            ))}
                                        </MDBInputGroup>
                                    </div>
                                </div>
                            </div>
                            <div className="my-2 text-center">
                                <MDBBtn color={"primary"} rounded onClick={createCourse} disabled={save}>
                                    {buttonText()}
                                </MDBBtn>
                            </div>
                        </div>

                        </MDBScrollbar>
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
        </div>
    );
}

export default CourseDetails;
