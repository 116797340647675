import React, {useState, useEffect} from "react";
import QuestionSection from "./QuestionSection";
import {useMDBCookies} from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";
import {MDBAccordion, MDBBtn,MDBScrollbar} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import axios from "axios";


function AddLectureQuestion({courseId, save, setSave}) {
    const [lectureQuestionId, setLectureQuestionId] = useState(1);
    const [course, setCourse] = useState([]);
    const [userId] = useMDBCookies('user-id', '');
    const [lectureQuestions, setLectureQuestions] = useState([{sectionId: "", lectureId: "", questions: []}]);


    useEffect(() => {
        axios
            .get(
                `https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/course?courseId=${courseId}&userId=${userId}`
            )
            .then((response) => {
                setCourse(response["data"]);
            });
    }, [userId, courseId, save]);


    const updateLectureQuestions = (questions, id) => {
        let newState = lectureQuestions;
        newState[id] = questions;
        setLectureQuestions(newState);
    };


    const createQuestions = () => {
        lectureQuestions.forEach(section => {
            axios
                .post(
                    "https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/add-questions/", section
                )
                .then(response => setSave(true));
        })
    };

    return (
        <>
          <MDBScrollbar suppressScrollX style={{height: window.innerHeight}}>
            <h3 className="my-4">
                Add Lecture Questions
            </h3>
            <MDBAccordion initialActive={"1"}>
                {[...Array(lectureQuestionId)].map((el, index) => (
                    <QuestionSection
                        key={index}
                        lectureQuestionId={index + 1}
                        addLectureQuestions={updateLectureQuestions}
                        course={course}
                        courseId={courseId}
                    />
                ))}
            </MDBAccordion>

            <div className="justify-content-center py-3">
                <MDBBtn
                    color="light" rounded className={"text-primary"}
                    onClick={() => {
                        setLectureQuestionId(lectureQuestionId + 1);
                        setLectureQuestions([...lectureQuestions, {}]);
                    }}
                >
                    Add Questions to another Lecture
                </MDBBtn>
            </div>
            <div className="m-4">
                <MDBBtn color="primary" onClick={() => createQuestions()} disabled={save} rounded>
                    Save
                </MDBBtn>
            </div>
            </MDBScrollbar>
        </>
    );
}

export default AddLectureQuestion;

