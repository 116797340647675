import React, {useEffect, useState} from "react";
import Header from "../utils/Header";
import AdminDasboardNav from "./AdminDashboardNav";
import {
    MDBCard, MDBCardTitle, MDBCardText, MDBChart
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import axios from "axios";
import LoadingScreen from "../utils/LoadingScreen";

function AdminDasboard() {
    const [instructor, setInstructor] = useState({});
    const [student, setStudent] = useState({});
    const [courseList, setCourseList] = useState({});

    useEffect(() => { // GET request using axios inside useEffect React hook
        axios.get(`https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/get-instructordata`)
            .then(response => setInstructor(response.data.instructorData));
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

    useEffect(() => {
        axios.get(`https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/get-studentdata`)
            .then(response => setStudent(response.data.studentData));
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

    useEffect(() => {
        let userData = {
            userId: -1,
            userType: "admin"
        };
        axios.post(`https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/courses`,
            JSON.stringify(userData))
            .then(response => setCourseList(response.data.courseData));
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

        return (
            <>
                <div>
                    <Header/>
                    <div className="col-md-12 col-sm-9">
                    <AdminDasboardNav/>
                    <div className="col-md-12 col-sm-9" style={{width: "100%", height: "100%", paddingLeft: "25%"}}>
                        <div className='d-flex flex-wrap'>
                            <div>
                                <MDBCard className='mt-4 mb-3 p-2 ms-5' style={{width: '15rem'}}>
                                    <MDBCardTitle className='m-2'>Total Instructor</MDBCardTitle>
                                    <MDBCardText style={{fontSize: "50px"}}>{instructor.length}</MDBCardText>
                                </MDBCard>
                            </div>
                            <div>
                                <MDBCard className='ms-5 mt-4 mb-3 p-2' style={{width: '15rem'}}>
                                    <MDBCardTitle className='m-2'>Total Students</MDBCardTitle>
                                    <MDBCardText style={{fontSize: "50px"}}>{student.length}</MDBCardText>
                                </MDBCard>
                            </div>
                            <div>
                                <MDBCard className='ms-5 mt-4 mb-3 p-2' style={{width: '15rem'}}>
                                    <MDBCardTitle className='m-2'>Total Courses</MDBCardTitle>
                                    <MDBCardText style={{fontSize: "50px"}}>{courseList.length}</MDBCardText>
                                </MDBCard>
                            </div>
                        </div>

                        <div className="ps-5 pe-5 pt-3 pb-4">
                            <h5 className="p-2">Courses offered and student enrolled in each month</h5>
                            <div className="card p-4">
                                <MDBChart
                                    type='line'
                                    data={{
                                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                        datasets: [
                                            {
                                                label: 'Students Enrolled',
                                                data: [5, 20, 10, 2, 25, 7, 9, 8],
                                                order: 1,
                                                yAxisID: 'y',
                                            },
                                            {
                                                label: 'Courses offered',
                                                yAxisID: 'y2',
                                                data: [5, 4, 6, 2, 3, 2, 7, 4],
                                                type: 'line',
                                                order: 0,
                                                backgroundColor: 'rgba(66, 133, 244, 0.0)',
                                                borderColor: '#94DFD7',
                                                borderWidth: 2,
                                                pointBorderColor: '#94DFD7',
                                                pointBackgroundColor: '#94DFD7',
                                            },
                                        ],
                                    }}
                                    options={{
                                        scales: {
                                            y1: {
                                                stacked: false,
                                                position: 'left',
                                                grid: {
                                                    drawOnChartArea: false,
                                                    drawBorder: false,
                                                    drawTicks: false,
                                                },
                                                ticks: {
                                                    display: false,
                                                },
                                            },
                                            y2: {
                                                stacked: false,
                                                position: 'right',
                                                grid: {
                                                    drawOnChartArea: false,
                                                },
                                                ticks: {
                                                    beginAtZero: true,
                                                },
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <h5>Student progress in Courses</h5>
                        <div className="ps-5 pe-5 pt-2 pb-5">
                            <div className="card p-4">
                                <MDBChart
                                    type='bar'
                                    data={{
                                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                                        datasets: [
                                            // First dataset (bar)
                                            {
                                                label: 'Courses',
                                                data: [10, 28, 20, 5, 25, 18, 7],
                                                order: 2,
                                            },
                                            // Second dataset (line)
                                            {
                                                label: 'Student progress',
                                                data: [3, 23, 1, 2, 20, 2, 7, 4],
                                                type: 'line',
                                                order: 1,
                                                backgroundColor: 'rgba(66, 133, 244, 0.0)',
                                                borderColor: '#94DFD7',
                                                borderWidth: 2,
                                                pointBorderColor: '#94DFD7',
                                                pointBackgroundColor: '#94DFD7',
                                                lineTension: 0.0,
                                            },
                                        ],
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
}

export default AdminDasboard;