/**
 * Profile Page contains:
   -User details (Name ,email,etc)
**/

//Import axios for API's
import axios from "axios";

//Import React components
import React, {useState, useEffect} from "react";

//Import MDB components
import {MDBInput, MDBBtn, MDBSpinner} from '@krtrimaiq-cognitive-solutions/mdb-react-ui-kit';
import {useMDBCookies} from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";

//Import components required to render page
import Header from "../components/utils/Header";


function Profile() {
    //Get user-id saved as cookies on login
    const [userId] = useMDBCookies('user-id', '');

    //UseState react hook lets to add state variables-profile(response from profile API call)
    const [profile, setProfile] = useState({});
    const [submit, setSubmit] = useState(false);

    //useEffect react hook renders when page loads and rerenders when parameters described changes(userId)
    useEffect(() => { 
        // GET request using axios from profile API to get user details
        axios.get(`https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/profile?userId=${userId}`)
            .then(response => setProfile(response.data));
    }, [userId]);

    //update profile data on edit
    const updateData = (e) => {
        const value = e.target.value;
        setProfile({
            ...profile,
            [e.target.name]: value
        });
    };
  
    //edit profile (profile API) api call on click of submit bitton after edit
    const updateProfile = (e) => {
        e.preventDefault();
        setSubmit(true);
        axios.post(`https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/profile?userId=${userId}`,
            JSON.stringify({
                userId: userId,
                emailId: profile.emailId,
                firstName: profile.firstName,
                lastName: profile.lastName,
                biography: profile.biography,
                address:profile.address,
                city: profile.city,
                phoneNumber: profile.phoneNumber,
                pincode:profile.pincode
            })
        )
        .then((response) => {
            setProfile(response.data);
            setSubmit(false);
        })

    };

    //button text based on if information being edited
    const buttonText = () => {
        if (submit)
            return (
            <MDBSpinner color='light' size="sm">
                <span className='visually-hidden'>Loading...</span>
            </MDBSpinner>
            )
        else
            return ("Submit")
    }
    
    return (
        <div><Header/>
            <div className='d-flex flex-wrap justify-content-center p-4'>
                <div className="card col-lg-6">
                    <h3 className="text-center mt-3">Profile</h3>
                    <div className="mt-2 ms-5 me-5 mb-4">
                        <MDBInput className='mb-3' label='Email' id='emailId' name="emailId" type='email'
                                  value={profile.emailId} onChange={updateData}/>
                        <MDBInput className='mb-3' label='User Type' id='userType' name="userType" type='text'
                                  value={profile.userType} disabled />
                        <MDBInput className='mb-3' label='First Name' id='firstName' name="firstName" type='text'
                                  value={profile.firstName} onChange={updateData}/>
                        <MDBInput className='mb-3' label='Last Name' id='lastName' name="lastName" type='text'
                                  value={profile.lastName} onChange={updateData}/>
                        <MDBInput className='mb-3' label='Biography' id='biography' name="biography" type='text'
                                  value={profile.biography} onChange={updateData}/>
                        <MDBInput className='mb-3' label='Address' id='address' name="address" type='text'
                                  value={profile.address} onChange={updateData}/>
                        <MDBInput className='mb-3' label='City' id='city' name="city" type='text'
                                  value={profile.city} onChange={updateData}/>
                        <MDBInput className='mb-3' label='Phone Number' id='phoneNumber' name="phoneNumber" type='number'
                                  value={profile.phoneNumber} onChange={updateData}/>
                        <MDBInput className='mb-3' label='Pincode' id='pincode' name="pincode" type='number'
                                  value={profile.pincode} onChange={updateData}/>
                      </div>
                    <div>
                        <MDBBtn variant="primary" active className="mb-3" onClick={updateProfile}>
                            {buttonText()}
                        </MDBBtn>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;