/**
 * Home Page contains:
   -Header(with login modal)
   -Carousel
   -Popular Courses slider
   -Popular Tags slider
   -All courses slider
**/

//Import axios for API's
import axios from "axios";

//Import React components
import React, {useState, useEffect} from "react";

//Import MDB components
import { MDBContainer, MDBCol} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";

//Import components required to render page
import Header from "../components/utils/Header";
import Slider from "../components/utils/Slider";
import Carousel from "../components/utils/Carousel";

function Home() {
    //UseState react hook lets to add state variables-popularCourses(top 5 courses),popularTags(top 5 courses with popular tags)
    const [allCourses, setAllCourses] = useState([]);
    const [popularCourses, setPopularCourses] = useState([]);
    const [popularTags, setPopularTags] = useState([]);
    const [tags, setTags] = useState(false);
    const [courses, setCourses] = useState(false);

    //useEffect react hook renders when page loads and rerenders when parameters described changes
    useEffect(() => {
        //data for post request
        let userData = {
            userId: -1,
            userType: "admin"
        };
        //post request to get-courses API
        axios.post("https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/courses",JSON.stringify(userData))
            .then(response => setAllCourses(response.data.courseData));
    }, []);

    useEffect(() => {
        //get request to popular-courses API
        axios.get(`https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/popular-courses`)
          .then((response) => {setPopularCourses(response["data"]);});
      },[]);

      useEffect(() => {
        //get request to popular-tags API
        axios.get(`https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/popular-tags`)
          .then((response) => {setPopularTags(response["data"]);});
      },[]);

    useEffect(() => {
           //set variable to true to display component
          if(popularTags.length>0)
          {
            setTags(true)
          }
          if(popularCourses.length>0)
          {
            setCourses(true)
          }
    },[popularCourses,popularTags]);


    // if all courses available display components
    if (allCourses.length > 0 )
        return (
            <div>
                <Header/>
                <Carousel />
                <MDBContainer className={"py-3 d-flex justify-content-between flex-wrap"}>
                   {courses &&
                        <MDBCol md="12">
                            <div className={"mx-2"} style={{fontSize: "25px", fontWeight: 500, textAlign: "start"}}>
                                Popular Courses
                            </div>
                            <div className={"py-2 mx-2"} >
                                <Slider courses={popularCourses}/>
                            </div>
                        </MDBCol>
                    }
                    <MDBCol md="12">
                        <div className={"mx-2"} style={{fontSize: "25px", fontWeight: 500, textAlign: "start"}}>
                           Recommended Courses
                        </div>
                        <div className={"py-2 mx-2"} >
                           <Slider courses={allCourses}/>
                        </div>
                    </MDBCol>
                    {tags &&
                        <MDBCol md="12">
                            <div className={"mx-2"} style={{fontSize: "25px", fontWeight: 500, textAlign: "start"}}>
                            Popular Tags
                            </div>
                            <div className={"py-2 mx-2"} >
                            <Slider courses={popularTags}/>
                            </div>
                        </MDBCol>
                    }
                </MDBContainer>
            </div>
        );
    else
        return (
            <>
            <Header/>
            <Carousel />
            <MDBContainer className={"py-3 d-flex justify-content-between flex-wrap"}>
                   {courses &&
                        <MDBCol md="12">
                            <div className={"mx-2"} style={{fontSize: "25px", fontWeight: 500, textAlign: "start"}}>
                                Popular Courses
                            </div>
                            <div className={"py-2 mx-2"} >
                            No Courses Avilable
                            </div>
                        </MDBCol>
                    }
                    <MDBCol md="12">
                        <div className={"mx-2"} style={{fontSize: "25px", fontWeight: 500, textAlign: "start"}}>
                           Recommended Courses
                        </div>
                        <div className={"py-2 mx-2"} >
                           No Courses Avilable
                        </div>
                    </MDBCol>
                    {tags &&
                        <MDBCol md="12">
                            <div className={"mx-2"} style={{fontSize: "25px", fontWeight: 500, textAlign: "start"}}>
                            Popular Tags
                            </div>
                            <div className={"py-2 mx-2"} >
                             No Courses Avilable
                            </div>
                        </MDBCol>
                    }
                </MDBContainer>
            </>
        );
}

export default Home;
