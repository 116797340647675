import React from "react";
import {MDBMultiCarousel} from "@krtrimaiq-cognitive-solutions/mdb-react-multi-carousel";
import {
    MDBCard, MDBCardBody, MDBCardImage,
    MDBCardText, MDBCardFooter
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import {Link} from "react-router-dom";
import Ratings from "./Ratings";
import ProgressBar from "./ProgressBar";
import instructor from '../../assets/instructor.jpg';
import {useMDBCookies} from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";

const Slider = (props) => {
    const courses = props.courses;
    const progress = props.progress;
    let navType = "about-course";
    if (props.view) navType = "view-course";
    const [userType] = useMDBCookies('user-type', '');

    return (
        <MDBMultiCarousel className="d-flex justify-content-start my-2">
            {courses.map((course) => (
                    <Link className="multi-carousel-item mx-2 py-3 text-black d-flex h-100" to={`/${navType}/${course["_id"]["$oid"]}`}>
                        <MDBCard className='d-flex h-100 mx-2' style={{width: "300px"}}>
                            <MDBCardImage
                                src={course.courseImgUrl}
                                style={{height: "180px", borderRadius: "4px"}}
                            />
                            <MDBCardBody className={"d-flex flex-column justify-content-start text-wrap"}>
                                <div style={{fontSize: "20px", fontWeight: 500, textAlign: "start"}}>
                                    {course.courseTitle}
                                </div>
                                <div style={{fontSize: "16px", textAlign: "start"}}>
                                    {course.courseDesc}
                                </div>
                            </MDBCardBody>
                            {progress && (userType === "student") && (
                                <div>
                                <ProgressBar progress={((course.courseCompletedDuration/course.courseDuration)*100)} iconColor={"primary"} small />
                                <div style={{fontSize: "12px", textAlign: "end"}}>
                                {((course.courseCompletedDuration/course.courseDuration)*100).toFixed(0)}%
                                </div>
                                </div>
                            )}
                            <MDBCardFooter 
                            className={'d-flex justify-content-between align-items-center'}
                            >
                              <div>
                                <img
                                  src={instructor}
                                  className="rounded-circle"
                                  alt=""
                                  style={{ width: '45px', height: '45px' }}
                                />
                              </div>
                                <div>
                                   <div> {course.courseInstructor.firstName}</div> <div>{course.courseInstructor.lastName}</div>
                                </div>
                                <MDBCardText>
                                    <Ratings rating={course.averageRating} courseId={course['_id']['$oid']} readonly small />
                                </MDBCardText>
                            </MDBCardFooter>
                        </MDBCard>
                    </Link>
            ))}
        </MDBMultiCarousel>
    );
};

export default Slider;
