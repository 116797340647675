import React, {useState, useEffect} from "react";
import {
    MDBInput,
    MDBBtn,
    MDBTextArea,
    MDBCol,
    MDBRadio
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";

const Questions = ({questionNo, lectureId, addQuestions}) => {
    const [answer, setAnswer] = useState(null);
    const [question, setQuestion] = useState("");
    const [duration, setDuration] = useState(0);
    const [optionCount, setOptionCount] = useState(2);
    const [option, setOption] = useState([]);

    const updateOption = (opt, i) => {
        let Option = [...option];
        Option[i] = opt;
        setOption(Option);
    };

    useEffect(() => {
        addQuestions(
            {
                "_id": `${lectureId}_${questionNo}`,
                "question": question,
                "answer": option[answer],
                "options": option,
                "duration": duration
            },
            questionNo - 1
        );
    }, [lectureId, questionNo, questionNo, question, answer, option, duration])

    return (
        <div className="card d-flex flex-row p-4 my-2 justify-content-between">
            <div className="col-12 d-flex flex-column justify-content-between">
                <h5 className="d-flex justify-content-start">
                    Question {questionNo}
                </h5>
                <div className={"d-flex m-3 align-items-center flex-md-nowrap flex-wrap"}>
                    <MDBCol className="text-start me-md-5" md={3} size={12}>
                        Select Question Duration*
                    </MDBCol>
                    <MDBCol md={4} size={12}>
                        <MDBInput label='Duration in Seconds' type='number' required
                                  onChange={e => setDuration(parseInt(e.target.value))}/>
                    </MDBCol>
                </div>
                <div className="d-flex m-3 align-items-center flex-md-nowrap flex-wrap">
                    <MDBCol className="text-start me-md-5" md={3} size={12}>
                        Enter Your Question*
                    </MDBCol>
                    <MDBCol md={8} size={12}>
                        <MDBTextArea label="Question" type="text"
                                     onChange={(e) => setQuestion(e.target.value)}
                                     required/>
                    </MDBCol>

                </div>
                <div className={"d-flex m-3 align-items-start flex-column"}>
                    <MDBCol className="text-start" md={3} size={12}>
                        Options*
                    </MDBCol>
                    <MDBCol size={12}>
                        <div className={"d-flex flex-column"}>
                            {[...Array(optionCount)].map((el, index) => (
                                <div key={index} className={"d-flex justify-content-between flex-md-nowrap flex-wrap my-2"}>
                                    <MDBCol size={12} md={8}>
                                        <MDBInput key={index} type="text" required label={`Option ${index + 1}`}
                                                  onChange={e => updateOption(e.target.value, index)} />
                                    </MDBCol>
                                    <MDBCol size={12} md={3}>
                                        <MDBRadio name={"answer"} label={"Correct Answer"} value={index}
                                                  onClick={e => setAnswer(parseInt(e.target.value))} />
                                    </MDBCol>
                                </div>

                            ))}
                        </div>
                    </MDBCol>
                    <MDBBtn
                        type="submit"
                        className={"d-flex text-dark my-3 justify-content-start"}
                        color={"light"}
                        rounded
                        onClick={() => {
                            setOptionCount(optionCount + 1);
                            setOption([...option, ""]);
                        }}
                    >
                        <div style={{color: "navy"}}>Add Option</div>
                    </MDBBtn>
                </div>
            </div>
        </div>
    );
};

export default Questions;
