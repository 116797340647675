import React, {useState} from "react";
import {
    MDBSideNav,
    MDBSideNavMenu,
    MDBSideNavItem,
    MDBSideNavLink,
    MDBIcon
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";

function AdminDasboard() {
    const [colorOpen, setColorOpen] = useState(true);
    const [color, setColor] = useState('primary');


    return (
        <>
            <MDBSideNav
                style={{
                    marginTop: "4rem",
                    marginBottom: "20rem",
                    width: "25%",
                    maxWidth:"50%",
                    backgroundColor: "white",
                }}
                closeOnEsc={false}
                backdrop={false}
                color={color} getOpenState={(e) => setColor(e)}
            >
                <MDBSideNavMenu className="m-2 mt-3 col-md-8 col-sm-12">
                    <MDBSideNavItem>
                        <MDBSideNavLink className="t1 text-black d-flex" href={`/#/admin-dashboard`}>
                            <MDBIcon className="px-3" fas icon="chart-line"/>
                            Metrics
                        </MDBSideNavLink>
                    </MDBSideNavItem>
                    <hr className="divider-horizontal text-black"/>
                    <MDBSideNavItem>
                        <MDBSideNavLink className="t1 text-black d-flex" href={"/#/instructors-list"}>
                            <MDBIcon className="px-3" fas icon="th-list"/>
                            List of Instructors
                        </MDBSideNavLink>
                    </MDBSideNavItem>
                    <hr className="divider-horizontal"/>
                    <MDBSideNavItem>
                        <MDBSideNavLink className="t1 text-black d-flex" href={"/#/students-list"}>
                            <MDBIcon className="px-3" fas icon="th-list"/>
                            List of Students
                        </MDBSideNavLink>
                    </MDBSideNavItem>
                    <hr className="divider-horizontal"/>
                    <MDBSideNavItem>
                        <MDBSideNavLink className="t1 text-black d-flex" href={`/#/course-list`}>
                            <MDBIcon className="px-3" fas icon="list-ul"/>
                            List of Courses
                        </MDBSideNavLink>
                    </MDBSideNavItem>
                    <hr className="divider-horizontal"/>
                </MDBSideNavMenu>
            </MDBSideNav>
        </>
    );
}

export default AdminDasboard;
