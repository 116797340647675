/**
 * Preview Page contains course details including:
   -Course Title, Image, Instructor, Duration,etc.
   -Course Contents-sections and lecture names.
   -Course objectives.
   -Course reviews and ratings.
   -Preview video.
 * Accessed by user from Homepage and Dashboard.
**/

//Import axios for API's
import axios from "axios";

//Import React components
import React, {useState, useEffect} from "react";


//Import MDB components
import {MDBCard,MDBCardBody,MDBCardImage,MDBCol,MDBBtn,MDBContainer,MDBBadge,MDBAccordionItem,MDBAccordion} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import {useMDBCookies} from '@krtrimaiq-cognitive-solutions/mdb-react-cookies-management';

//Import App.js and other components required to render page
import SectionContentHeader from "../../components/utils/SectionContentHeader";
import LoadingScreen from "../../components/utils/LoadingScreen";
import CourseDate from "../../components/utils/CourseDate";

function  Preview({courseId, save,setSave,setNext}) {
    //Get user-id saved as cookies on login
    const [userId] = useMDBCookies('user-id', '');

     //UseState react hook lets to add state variables-course(response of getCourse API),text(text displayed in button,preview(if video available))
    const [course, setCourse] = useState({});
    const [preview, setPreview ]=useState(false)

    //useEffect react hook renders when page loads and rerenders when parameters described changes(courseId,userId)
    useEffect(() => {
        if(courseId!=="")
            // GET request from getCourse API using axios to access course details
            axios.get(`https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/course?courseId=${courseId}&userId=${userId}`)
                .then((response) => {
                    setCourse(response.data);
                    setPreview(true)
                });
    }, [courseId, userId]);

    const gotoDash = () => {
        setSave(true)
        setNext(true)
    };
   
//if course details available load componenets else spinner rendered
    if (Object.keys(course).length > 0)
        return (
            <div>
                <h3 className="my-4">
                       Preview
                </h3>
                <MDBContainer className={"d-flex flex-wrap justify-content-between"}>
                    <MDBCol className={"d-flex flex-column pt-4 ms-2"} md={4}>
                       <div style={{fontSize: "22px", fontWeight: 500}} className="d-flex">
                            {course.courseTitle}
                       </div>
                       <div className={"d-flex justify-content-start"}>
                            Instructor : {course.courseInstructor.firstName} {course.courseInstructor.lastName}
                       </div>
                       <div className="d-flex justify-content-start my-2" style={{textAlign: "start"}}>
                          {course.courseDesc}
                       </div>
                       <div className={"mt-2"} style={{fontSize: "24px", fontWeight: 500, textAlign: "start"}}>
                         Course Contents
                       </div>
                        <MDBCard className={"d-flex flex-row my-3"} style={{textAlign: "start",width:"400px"}}>
                            <MDBCardBody className={"d-flex flex-column py-3"}>
                                <MDBAccordion initialActive={"0"}>
                                    {course.sections.map((section, i) =>
                                        <MDBAccordionItem key={i} collapseId={`${i}`} headerTitle={<SectionContentHeader section={section} />}>
                                            <div className={"d-flex flex-column"}>
                                                {section.lectures.map((lecture, j) =>
                                                    <div key={j} className={"d-flex justify-content-between my-1"}>
                                                        <div>
                                                            {lecture.lectureTitle}
                                                        </div>
                                                        <div>
                                                            {parseInt(lecture.lectureDuration / 60)}:{(lecture.lectureDuration % 60) > 9?(lecture.lectureDuration % 60):"0"+(lecture.lectureDuration % 60)}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </MDBAccordionItem>
                                    )}
                                </MDBAccordion>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol className={"d-flex flex-column me-2"} md={7}>
                       {preview &&
                       <div>
                            <video id="lectureVideo" controls style={{width: "100%"}}>
                                <source src={course.sections[0].lectures[0].lectureUrl}/>
                                Sorry, your browser doesn't support videos.
                            </video>
                        </div>
                       }
                       <div className={"mt-2"} style={{fontSize: "24px", fontWeight: 500, textAlign: "start"}}>
                            Course Objectives
                       </div>
                        <MDBCard className={"d-flex flex-row my-3"} style={{textAlign: "start"}}>
                            <MDBCol>
                                <MDBCardBody className={"d-flex flex-column pb-3 pt-3"} style={{}}>
                                    <ul className="ulColoum">
                                        {course.courseObj.map(
                                            (obj, i) => <li className="liList">{obj}</li>
                                        )}
                                    </ul>
                                </MDBCardBody>
                            </MDBCol>
                        </MDBCard>
                        <div className={"mt-2"} style={{fontSize: "24px", fontWeight: 500, textAlign: "start"}}>
                          About Course    
                        </div>
                        <MDBCard className="d-flex flex-column my-3">
                            <MDBCardBody className={"d-flex flex-wrap justify-content-between p-3"}>
                                <MDBCol md={4}>
                                    <MDBCardImage style={{width: "100%", height: "100%", borderRadius: "4px"}} src={course.courseImgUrl} />
                                </MDBCol>
                                <MDBCol md={8} className={"d-flex flex-column justify-content-end ps-4"}>
                                    <div className="d-flex justify-content-start my-2" style={{textAlign: "start",fontSize: "22px", fontWeight: 500}}>
                                       {course.courseTitle}
                                    </div>
                                    <div className={"d-flex justify-content-between align-items-center my-1"} style={{fontSize: "14px"}}>
                                        <div className={"d-flex align-items-center"}>
                                            <div className={"me-2"}>
                                                {course.sections.length} sections
                                            </div>
                                            <div className={"ms-2"}>
                                                {parseInt(course.courseDuration /3600)} hours
                                            </div>
                                        </div>
                                        <div>
                                            {parseFloat(course.courseDuration /(3600 * 4)).toFixed(1)} hours per week
                                        </div>
                                    </div>
                                    <div className={"d-flex justify-content-between mt-1 flex-wrap"} style={{fontSize: "14px"}}>
                                        <div className={"d-flex align-items-center"}>
                                            <div className={"me-2"} style={{fontWeight: 500}}>
                                                Course Created:
                                            </div>
                                            <div className={"ms-2"}>
                                                {<CourseDate date={course.courseCreatedDate['$date']} />}
                                            </div>
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBCardBody>
                            <MDBCardBody className={"justify-content-start p-3 align-items-center"}>
                                {course.tags.map(
                                    tag => <MDBBadge pill color='primary' light className={"me-2"}>{tag.toLowerCase()}</MDBBadge>
                                )}
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBContainer>
                <div className="m-4">
                <MDBBtn color="primary" onClick={() => gotoDash()} disabled={save} rounded>
                    Save
                </MDBBtn>
            </div>
            </div>
        );
    else
        return <LoadingScreen />
}

export default Preview;
