/**
 * View Course contains:
   -SideNavbar with sections and lectures
   -LectureVideo
   -Tabs
    ->Overview-about course detlais
    ->Supplementary Material
    ->Announcement
    ->Transcript
**/

//Import axios for API's
import axios from "axios";

//Import React components
import React, {useState,useEffect,useContext,useCallback,useRef,} from "react";
import {useParams} from "react-router";

//Import MDB components
import {MDBSideNav,MDBSideNavMenu,MDBSideNavItem,MDBScrollbar,MDBCard,MDBIcon,MDBSideNavLink,MDBTabs,MDBTabsItem,MDBTabsLink,MDBTabsContent,MDBTabsPane,MDBBtn,MDBTextArea,MDBCol,MDBCardBody,MDBAccordionItem,MDBAccordion} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import {useMDBCookies} from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";

//Import components required to render page
import {UrlContext} from "../App";
import Header from "../components/utils/Header";
import QuestionModal from "../components/viewCourse/QuestionModal";
import ProgressBar from "../components/utils/ProgressBar";
import Ratings from "../components/utils/Ratings";
import Reviews from "../components/utils/Reviews";
import DownloadCertificate from "../components/viewCourse/DownloadCertificate";
import SectionContentHeader from "../components/utils/SectionContentHeader";
import LoadingScreen from "../components/utils/LoadingScreen";
import SectionProgressbar from "../components/viewCourse/SectionProgressBar";

//Import assests
import announcement from "../assets/Announcement.jpg";
import instructor from "../assets/instructor.jpg";

function ViewCourse() {
    const [userId] = useMDBCookies("user-id", "");
    const [userType] = useMDBCookies("user-type", "");
    const {courseId} = useParams();
    const [course, setCourse] = useState([]);
    const [selectedLecture, setSelectedLecture] = useState({});
    const [selectedSection, setSelectedSection] = useState({});
    const [questionId, setQuestionId] = useState(0);
    const {setQuestionModal, playerRef} = useContext(UrlContext);
    const [currentTime, setCurrentTime] = useState(0);
    const [basicActive, setBasicActive] = useState("About Course");
    const [sectionActiveIndex, setSectionActiveIndex] = useState([]);
    const [lectureActiveIndex, setLectureActiveIndex] = useState([]);
    let marginValue = "60px";
    if (userType === "instructor") {
        marginValue = "130px";
    }

    const handleBasicClick = (value) => {
        if (value === basicActive) {
            return;
        }

        setBasicActive(value);
    };

    const onReady = useCallback(() => {
        let x = playerRef.current.currentTime;
        if (questionId < selectedLecture.questions.length) {
            if (parseInt(x) === selectedLecture.questions[questionId].duration) {
                setQuestionModal(true);
                playerRef.current.pause();
            }
        }
        if (Object.keys(selectedLecture).length > 0) {
            if (parseInt(x) === parseInt(selectedLecture.lectureDuration * 0.95)) {
                updateLectureProgress();
                console.log(playerRef.current.currentTime);
                changeLecture(sectionActiveIndex+1,lectureActiveIndex+1)
                window.location.reload(false);
            }
        }
    }, [questionId, playerRef, setQuestionModal, selectedLecture]);

    useEffect(() => {
        // GET request using axios inside useEffect React hook
        axios
            .get(
                `https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/course?courseId=${courseId}&userId=${userId}`
            )
            .then((response) => {
                setCourse(response.data);
                setCurrentTime(0)
                let selectLecture = false;
                let selectQuestion = false;
                response.data.sections.forEach((section, i) => {
                    section.lectures.forEach((lecture, j) => {
                        if (Object.keys(lecture).includes("completedDuration")) {
                            if (lecture.completedDuration < lecture.lectureDuration) {
                                if (!selectLecture) {
                                    setSelectedLecture(lecture);
                                    setLectureActiveIndex(j)
                                    setSelectedSection({
                                        _id: section["_id"],
                                        sectionName: section["sectionName"],
                                    });
                                    setSectionActiveIndex(i)
                                    setCurrentTime(lecture.completedDuration);
                                    selectLecture = true;
                                    lecture.questions.forEach((question, k) => {
                                        if (question.duration > lecture.completedDuration) {
                                            if (!selectQuestion) {
                                                setQuestionId(k);
                                                selectQuestion = true;
                                            }
                                        }
                                    });
                                }
                            }
                        } else {
                            if (!selectLecture) {
                                setSelectedLecture(lecture);
                                setLectureActiveIndex(j)
                                setSelectedSection({
                                    _id: section["_id"],
                                    sectionName: section["sectionName"],
                                });
                                setSectionActiveIndex(i)
                                setCurrentTime(0);
                                selectLecture = true;
                                selectQuestion = true;
                            }
                        }
                    });
                });
            });
    }, [courseId, userId]);

    useEffect(() => {
        if (
            typeof playerRef.current !== "undefined" &&
            playerRef.current !== null
        ) {
            playerRef.current.currentTime = currentTime;
            var video = document.getElementById('lectureVideo')
            var previousTime = currentTime
            console.log(previousTime)
            video.addEventListener('timeupdate', function (evt) {
                if (!video.seeking) {
                    previousTime = Math.max(previousTime, video.currentTime);
                }
            }, false);

            video.addEventListener('seeking', function (evt) {
                if (video.currentTime > previousTime) {
                video.currentTime = previousTime;
                }
            }, true);
        }
    }, [currentTime],playerRef.current);

    const updateLectureProgress = () => {
        setCurrentTime(0);
        axios.post(
            "https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/update-progress",
            JSON.stringify({
                userId: userId,
                courseId: courseId,
                lectureId: selectedLecture["_id"],
                completedDuration: selectedLecture.lectureDuration,
            })
        );
    };

    const changeLecture = (section, lecture) => {
        setSectionActiveIndex(section)
        setLectureActiveIndex(lecture)
        setSelectedLecture(course.sections[section].lectures[lecture]);
        setSelectedSection({
            _id: course.sections[section]["_id"],
            sectionName: course.sections[section]["sectionName"],
        });
    };

    const [mode, setMode] = useState("push");
    const contentRef = useRef(null);

    const handleResize = useCallback(() => {
        if (window.matchMedia("(min-width: 900px)")) {
            return setMode("push");
        }

        return setMode("push");
    }, []);


    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);


    if (Object.keys(course).length > 0)
        return (
            <div className={"d-flex flex-column "}>
                <Header/>
                <div className={"d-flex justify-content-between"}>
                    <MDBSideNav
                        className="pt-2 d-flex justify-content-between"
                        style={{marginTop: marginValue, width: "25%", height: "100vh"}}
                        backdrop={false}
                        mode={mode}
                        closeOnEsc={false}
                    >
                        <MDBSideNavMenu style={{width: "100%"}}>
                            <MDBScrollbar sidenav tag="ul" suppressScrollX style={{height: window.innerHeight - 250}} className={"col-md-12"}>
                                <MDBAccordion initialActive={0}>
                                    {course.sections.map((section, i) => (
                                        <MDBAccordionItem
                                            key={i}
                                            collapseId={`${i}`}
                                            bodyClassName="p-0"
                                           // style={ i>sectionActiveIndex?{pointerEvents: "none",opacity:0.5}:i>sectionActiveIndex+1?{opacity:1}:null }
                                            headerTitle={
                                                <SectionContentHeader
                                                    section={section}
                                                    active={section["_id"] === selectedSection["_id"]}
                                                />
                                            }
                                        >
                                            <div className={"d-flex flex-column"}>
                                                {section.lectures.map((lecture, j) => (
                                                    <MDBSideNavLink
                                                        key={j}
                                                        className={
                                                            "d-flex justify-content-start align-items-center "
                                                        }
                                                       // style={ (section['_id'] === selectedSection['_id'] && j>lectureActiveIndex)?{pointerEvents: "none",opacity:0.5}:(section['_id'] !== selectedSection['_id'] && j>lectureActiveIndex+1)?{opacity:1}:null }  
                                                        onClick={() => changeLecture(i, j)}
                                                        active={lecture["_id"] === selectedLecture["_id"]}
                                                    >
                                                        <div>
                                                            {typeof lecture.completedDuration ===
                                                            "undefined" ? (
                                                                <SectionProgressbar percentage={0}/>
                                                            ) : (
                                                                <SectionProgressbar
                                                                    percentage={parseInt(
                                                                        (lecture.completedDuration /
                                                                            lecture.lectureDuration) *
                                                                        100
                                                                    )}
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className={
                                                                "d-flex justify-content-between w-100 ms-3"
                                                            }
                                                        >
                                                            <div className={"text-truncate me-2"}>
                                                                {lecture.lectureTitle}
                                                            </div>
                                                            <div>
                                                                {parseInt(lecture.lectureDuration / 60)}:
                                                                {lecture.lectureDuration % 60 > 9
                                                                    ? lecture.lectureDuration % 60
                                                                    : "0" + (lecture.lectureDuration % 60)}
                                                            </div>
                                                        </div>
                                                    </MDBSideNavLink>
                                                ))}
                                            </div>
                                        </MDBAccordionItem>
                                    ))}
                                </MDBAccordion>
                            </MDBScrollbar>
                            <MDBSideNavItem
                                className={"py-4"}
                                style={{borderTop: "solid 1px #111B58"}}
                            >
                                <DownloadCertificate/>
                            </MDBSideNavItem>
                        </MDBSideNavMenu>
                    </MDBSideNav>
                    <div
                        style={{
                            width: "100%",
                            height: "100vh",
                            transition: "padding 0.3s linear",
                        }}
                        className="text-center bg-light"
                        ref={contentRef}
                    >
                        <div
                            className={"d-flex flex-column"}
                            style={{marginLeft: "25%", width: "75%"}}
                        >
                            <div>
                                <video
                                    id="lectureVideo"
                                    ref={playerRef}
                                    style={{
                                        width: "100%",
                                        display: "-webkit-media-controls-timeline none",
                                    }}
                                    onTimeUpdate={onReady}
                                    controls
                                    key={selectedLecture.lectureUrl}
                                >
                                    <source src={selectedLecture.lectureUrl}/>
                                    Sorry, your browser doesn't support videos.
                                </video>
                            </div>
                            <div>
                                <MDBTabs className="mb-3">
                                    <MDBTabsItem>
                                        <MDBTabsLink
                                            onClick={() => handleBasicClick("About Course")}
                                            active={basicActive === "About Course"}
                                        >
                                            About Course
                                        </MDBTabsLink>
                                    </MDBTabsItem>
                                    <MDBTabsItem>
                                        <MDBTabsLink>Supplementary Material</MDBTabsLink>
                                    </MDBTabsItem>
                                    <MDBTabsItem>
                                        <MDBTabsLink aria-disabled="true">
                                            Student Discussion
                                        </MDBTabsLink>
                                    </MDBTabsItem>

                                    <MDBTabs>
                                        <MDBTabsItem>
                                            <MDBTabsLink
                                                //   onClick={() => handleBasicClick('Announcement')} active={basicActive === 'Announcement'}
                                            >
                                                Announcement
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                    </MDBTabs>
                                    <MDBTabs>
                                        <MDBTabsItem>
                                            <MDBTabsLink
                                                  onClick={() => handleBasicClick('Transcript')} active={basicActive === 'Transcript'}
                                            >
                                                Transcript
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                    </MDBTabs>
                                </MDBTabs>
                                <MDBTabsContent>
                                    <MDBTabsPane show={basicActive === "About Course"}>
                                        <div>
                                            <div className={"m-5"}>
                                                <div
                                                    className={"mt-2"}
                                                    style={{
                                                        fontSize: "24px",
                                                        fontWeight: 500,
                                                        textAlign: "start",
                                                    }}
                                                >
                                                    {course.courseTitle}
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: "18px",
                                                        fontWeight: 500,
                                                        textAlign: "start",
                                                    }}
                                                >
                                                    {course.courseInstructor.firstName}{" "}
                                                    {course.courseInstructor.lastName}
                                                </div>
                                                <div style={{fontSize: "16px", textAlign: "start"}}>
                                                    {course.courseDesc}
                                                </div>
                                                <div className={"d-flex my-3 flex-wrap"}>
                                                    <MDBCol md={6}>
                                                        <div
                                                            className={"mt-2"}
                                                            style={{
                                                                fontSize: "24px",
                                                                fontWeight: 500,
                                                                textAlign: "start",
                                                            }}
                                                        >
                                                            About Lecture
                                                        </div>
                                                        <MDBCard
                                                            className="mx-2"
                                                            style={{minHeight: "178px"}}
                                                        >
                                                            <MDBCardBody
                                                                className={"d-flex flex-column"}
                                                                style={{textAlign: "start"}}
                                                            >
                                                                <div
                                                                    style={{fontSize: "18px", fontWeight: 500}}
                                                                >
                                                                    {selectedLecture.lectureTitle}
                                                                </div>
                                                                <div
                                                                    className={"mb-2"}
                                                                    style={{fontSize: "16px", fontWeight: 500}}
                                                                >
                                                                    {selectedSection.sectionName}
                                                                </div>
                                                                <div style={{fontSize: "16px"}}>
                                                                    {selectedLecture.lectureDesc}
                                                                </div>
                                                            </MDBCardBody>
                                                        </MDBCard>
                                                    </MDBCol>
                                                    <MDBCol md={6}>
                                                        <div
                                                            className={"mt-2"}
                                                            style={{
                                                                fontSize: "24px",
                                                                fontWeight: 500,
                                                                textAlign: "start",
                                                            }}
                                                        >
                                                            Course Ratings
                                                        </div>
                                                        <MDBCard className="mx-2">
                                                            <MDBCardBody className={"d-flex"}>
                                                                <div
                                                                    className={
                                                                        "d-flex flex-column align-items-center justify-content-between me-2"
                                                                    }
                                                                >
                                                                    <div
                                                                        className="d-flex"
                                                                        style={{fontSize: 36}}
                                                                    >
                                                                        {parseFloat(course.averageRating).toFixed(1)}
                                                                    </div>
                                                                    <Ratings
                                                                        className={"my-2"}
                                                                        rating={parseFloat(
                                                                            course.averageRating
                                                                        ).toFixed(1)}
                                                                        courseId={courseId}
                                                                        readonly={false}
                                                                    />
                                                                    <div className="d-flex justify-content-start">
                                                                        {`${course.ratingCount} ratings`}
                                                                    </div>
                                                                </div>
                                                                <div className={"d-flex flex-column ms-2 w-100"}>
                                                                    {course.ratingGroup.map((rating, i) => (
                                                                        <ProgressBar
                                                                            key={i}
                                                                            className={"my-2"}
                                                                            rounded
                                                                            progress={
                                                                                (rating * 100) / course.ratingCount
                                                                            }
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </MDBCardBody>
                                                        </MDBCard>
                                                    </MDBCol>
                                                </div>

                                                <div
                                                    className={"mt-2"}
                                                    style={{
                                                        fontSize: "24px",
                                                        fontWeight: 500,
                                                        textAlign: "start",
                                                    }}
                                                >
                                                    Course Reviews
                                                </div>
                                                {typeof course.reviews !== undefined &&
                                                typeof course.ratings !== undefined ? (
                                                    <Reviews
                                                        className={"my-3"}
                                                        reviews={course.reviews}
                                                        ratings={course.ratings}
                                                    />
                                                ) : (
                                                    <Reviews className={"my-3"} reviews={[]} ratings={0}/>
                                                )}
                                            </div>
                                        </div>
                                    </MDBTabsPane>
                                    <MDBTabsPane show={basicActive === "Supplementary Material"}>
                                        <div>
                                            <h4 className="ms-4 d-flex">Material</h4>
                                            <div className="m-4 d-flex">
                                                <div className="me-3">
                                                    <MDBCard
                                                        className="p-3"
                                                        style={{backgroundColor: "#f2f2f2"}}
                                                    >
                                                        <div>
                                                            Material.pdf
                                                            <MDBBtn className="ms-3" floating tag="a">
                                                                <MDBIcon fas icon="download"/>
                                                            </MDBBtn>
                                                        </div>
                                                    </MDBCard>
                                                </div>
                                                <div className="me-3">
                                                    <MDBCard
                                                        className="p-3"
                                                        style={{backgroundColor: "#f2f2f2"}}
                                                    >
                                                        <div>
                                                            Material1.doc
                                                            <MDBBtn
                                                                className="ms-3 justify-content-end align-item-end"
                                                                floating
                                                                tag="a"
                                                            >
                                                                <MDBIcon fas icon="download"/>
                                                            </MDBBtn>
                                                        </div>
                                                    </MDBCard>
                                                </div>
                                                <div className="me-3">
                                                    <MDBCard
                                                        className="p-3"
                                                        style={{backgroundColor: "#f2f2f2"}}
                                                    >
                                                        <div>
                                                            Material2.doc
                                                            <MDBBtn
                                                                className="ms-3 justify-content-end align-item-end"
                                                                floating
                                                                tag="a"
                                                            >
                                                                <MDBIcon fas icon="download"/>
                                                            </MDBBtn>
                                                        </div>
                                                    </MDBCard>
                                                </div>
                                                <div className="me-3">
                                                    <MDBCard
                                                        className="p-3"
                                                        style={{backgroundColor: "#f2f2f2"}}
                                                    >
                                                        <div>
                                                            Material3.pdf
                                                            <MDBBtn
                                                                className="ms-3 justify-content-end align-item-end"
                                                                floating
                                                                tag="a"
                                                            >
                                                                <MDBIcon fas icon="download"/>
                                                            </MDBBtn>
                                                        </div>
                                                    </MDBCard>
                                                </div>
                                            </div>
                                        </div>
                                    </MDBTabsPane>
                                    <MDBTabsPane show={basicActive === "Student Discussion"}>
                                        <div className="mb-5">
                                            <h4 className="ms-4 d-flex">Raise Question</h4>
                                            <MDBCard className="m-4 square rounded-5">
                                                <div className="p-3">
                                                    <h5 className="d-flex">Question</h5>
                                                    <div className="m-4">
                                                        <MDBTextArea
                                                            label="Enter Question"
                                                            id="raisequestion"
                                                            rows={4}
                                                            required
                                                        />
                                                        <MDBBtn
                                                            type="submit"
                                                            className="d-flex text-dark mt-4 "
                                                            rounded
                                                            color={"light"}
                                                        >
                                                            <div style={{color: "navy"}}>Submit</div>
                                                        </MDBBtn>
                                                    </div>
                                                </div>
                                            </MDBCard>
                                            <MDBAccordion initialActive={0} className="m-4">
                                                <MDBAccordionItem
                                                    collapseId={1}
                                                    headerTitle="Questions and answers"
                                                >
                                                    <div className="justify-content-start text-align-start">
                                                        <div className="d-flex ms-5">
                                                            Lecture Name : {selectedLecture.lectureTitle}
                                                        </div>
                                                        <div className="d-flex ms-5">
                                                            <b>what is Hacking?</b>
                                                        </div>
                                                        <hr className="divider-horizontal ms-4"/>
                                                        <div className="ms-5">
                                                            <div className="ms-5 d-flex">
                                                                <img
                                                                    src={instructor}
                                                                    class="rounded-circle m-2"
                                                                    alt=""
                                                                    style={{width: "45px", height: "45px"}}
                                                                />
                                                                {course.courseInstructor.firstName}{" "}
                                                                {course.courseInstructor.lastName}
                                                            </div>
                                                            <div className="d-flex ms-5">
                                                                the gaining of unauthorized access to data in a
                                                                system or computer.
                                                            </div>
                                                            <hr className="divider-horizontal"/>
                                                            <div className="m-4 d-flex">
                                                                <MDBIcon className="m-2" fas icon="reply"/>
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        textDecoration: "underline",
                                                                    }}
                                                                >
                                                                    Reply
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </MDBAccordionItem>
                                            </MDBAccordion>
                                        </div>
                                    </MDBTabsPane>
                                    <MDBTabsPane show={basicActive === "Announcement"}>
                                        <div>
                                            <h4 className="ms-4 d-flex">Announcement</h4>
                                            <img
                                                className="mt-1"
                                                src={announcement}
                                                alt="Announcement"
                                                style={{height: "300px", width: "800px"}}
                                            />
                                            <p
                                                className="ms-5 me-5 mt-3 mb-3"
                                                style={{textAlign: "justify"}}
                                            >
                                                Announcements allow instructors to communicate with
                                                students about course activities and post interesting
                                                course-related topics. Announcements are designed to allow
                                                instructors to broadcast information out to all members of
                                                a course or to all members of sections within a course.
                                            </p>
                                        </div>
                                    </MDBTabsPane>
                                    <MDBTabsPane show={basicActive === "Transcript"}>
                                        <div>
                                            <h4 className="ms-4 d-flex">Transcript</h4>
                                            <p
                                                className="ms-5 me-5 mt-3 mb-3"
                                                style={{textAlign: "justify"}}
                                            >
                                                {" "}
                                                A transcript is proof of education. It has a detailed
                                                record of all the subjects you have studied with your
                                                scores in the form of marks or grades given by the
                                                institution of study.
                                            </p>
                                        </div>
                                    </MDBTabsPane>
                                </MDBTabsContent>
                            </div>

                            <QuestionModal
                                id={questionId}
                                setQuestionId={setQuestionId}
                                questions={selectedLecture.questions}
                                lectureId={selectedLecture["_id"]}
                                lectureDuration={selectedLecture.lectureDuration}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    else return <LoadingScreen/>;
}

export default ViewCourse;
