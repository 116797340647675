import React, { useState, useEffect } from "react";
import Header from "./Header";
import {
  MDBContainer,
  MDBCol,
} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import CardGrid from "./CardGrid";
import LoadingScreen from "./LoadingScreen";
import axios from "axios";
import { useParams } from "react-router";
import SearchList from "./SearchList";

function SearchResult(props) {
  const { searchWord } = useParams();
  const [allCourses, setAllCourse] = useState([]);
  const [searchResultInstructor, setSearchResultInstructor] = useState("");
  const [searchResultTitle, setSearchResultTitle] = useState("");
  const [searchResultTag, setSearchResultTag] = useState("");

  useEffect(() => {
    axios
      .get(
        `https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/course-search?searchWord=${encodeURI(
          searchWord
        )}`
      )
      .then((response) => setAllCourse(response.data));
  }, [searchWord]);

  useEffect(() => {
    setSearchResultInstructor("");
    setSearchResultTag("");
    setSearchResultTitle("");
    allCourses.forEach((course, i) => {
      if (course["searchType"] === "Instructor") {
        setSearchResultInstructor("Instructor");
      }
      if (course["searchType"] === "courseTitle") {
        setSearchResultTitle("CourseTitle");
      }
      if (course["searchType"] === "courseTag") {
        setSearchResultTag("CourseTag");
      }
    });
  }, [searchWord, allCourses]);

  if (allCourses.length > 0)
    return (
      <div>
        <Header />
        <div
              className={"mx-2 py-3 ms-4"}
              style={{ fontSize: "24px", fontWeight: 500, textAlign: "start" }}
            >
              Search Result for {searchResultInstructor} {searchResultTag}{" "}
              {searchResultTitle}
          </div>
        <MDBContainer className={"d-flex justify-content-between"}>
        
          <MDBCol md="3">
          
          <div className="py-2">
            <SearchList/>
            </div>
          </MDBCol>
          <MDBCol md="9">
            <div className={"py-2 mx-2"}>
              <CardGrid courses={allCourses} />
            </div>
          </MDBCol>
        </MDBContainer>
      </div>
    );
  else return <LoadingScreen />;
}
export default SearchResult;
