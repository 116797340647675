import React from 'react';
import {MDBSpinner} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";

const LoadingScreen = () => {
    return (
        <div>
            <div className='d-flex justify-content-center align-items-center' style={{height: "90vh"}}>
                <MDBSpinner role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </MDBSpinner>
            </div>
        </div>
    )
}

export default LoadingScreen;