import React, {useState, useEffect} from "react";
import Header from "../utils/Header";
import AdminDasboardNav from "./AdminDashboardNav";
import {MDBTableEditor} from "@krtrimaiq-cognitive-solutions/mdb-react-table-editor";
import axios from "axios";
import {useMDBCookies} from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";


function ListofCourse(props) {
    const [userId] = useMDBCookies('user-id', '');
    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [editCourse,setEditCourse] = useState({});
    const [editCourseId,setEditCourseId]= useState("");


    const [asyncData, setAsyncData] = useState({
        columns: [
            {label: 'Course Id', field: '_id'},
            {label: 'Course Name', field: 'courseTitle'},
            {label: 'Course Instructor', field: 'courseInstructor'},
            {label: 'Course Created Date', field: 'courseCreatedDate'},
            {label: 'Course Approved', field: 'courseApproved'},
            {label: 'Last Updated Date', field: 'lastUpdatedDate'}
        ],
        rows: [],
    });

    useEffect(() => {
        if (loading) {
            let userData = {
                userId: -1,
                userType: "admin"
            };
            axios.post(
                "https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/courses",
                JSON.stringify(userData))
                .then((response) =>
                    setTimeout(() => {
                        setAsyncData({
                            columns: asyncData.columns,
                            rows: response.data.courseData.map((course) => ({
                                ...course,
                                courseInstructor: `${course.courseInstructor.firstName}  ${course.courseInstructor.lastName}`,
                                _id: course['_id']['$oid'],
                                courseCreatedDate: course.courseCreatedDate['$date'],
                                lastUpdatedDate: course.lastUpdatedDate['$date']
                            })),
                        });

                    }, 3000)
                );


        }
    }, [loading, asyncData.columns]);

    useEffect(() => {
        // if (asyncData.rows.length === 0) {
        //     setLoading(true);
        // } else {
            setLoading(false);
        //}
    }, [asyncData.rows]);

    function deleteCourse (id) {
        axios.post(
            "https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/delete-course",
            JSON.stringify({courseId: id})
            )
            .then((response) => setLoading(true));
    }

    
    const updateCourse = ({...data}) => {
        setSubmit(true);
        axios.post(
            `https://x20sfr8b51.execute-api.ap-south-1.amazonaws.com/mapmyclasses/create-edit-course`,
            JSON.stringify({
                courseId:data._id,
                courseTitle: data.courseTitle,
                courseInstructor:data.courseInstructor
                
            })
        )
            .then((response) => {
                setLoading(true);
                setEditCourse(response.data);
            })

    };


    return (
        <>
            <div>
                <Header/>
                <AdminDasboardNav/>
                <div style={{width: "100%", height: "100%", paddingLeft: "25%"}}>
                    <div className="pt-3 ps-4 pe-4 pb-4">
                        <div className="card p-4">
                             <MDBTableEditor confirmDelete
                                            mode='modal'
                                            isLoading={loading}
                                            data={asyncData}
                                            entriesOptions={[10, 20, 30, 40]}
                                            entries={10}
                                            onModify={(modifiedData,j) => {updateCourse( modifiedData[j]);}}
                                            onDelete={(e) => {deleteCourse(asyncData.rows[e]["_id"])}}/> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListofCourse;
