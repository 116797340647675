/**
 * Create Course Page used by instructor to create courses contains Stepper with following components:
   -Course Structure(tips to create a good course)
   -Course Details(Title,Image,tags,objectives,etc)
   -Create Lecture
   -AddLectureQuestions
   -AddSupplementary Material
   -Preview
 * Accessed by instructor from createcourse tab.
**/

//Import React components
import React, {useState, useEffect, useCallback, useRef} from "react";
import {useNavigate} from "react-router-dom";

//Import MDB components
import {MDBStepper,MDBStepperStep,MDBStepperForm,MDBBtn} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";

//Import components required to render page
import AddLectureQuestions from "../components/createCourse/AddLectureQuestions";
import AddSupplementaryMaterial from "../components/createCourse/AddSupplementaryMaterial";
import CourseDetails from "../components/createCourse/CourseDetails";
import CourseStructure from "../components/createCourse/CourseStructure";
import CreateLectures from "./CreateLectures";
import Header from "../components/utils/Header";
import Preview from "../components/createCourse/Preview";

function CreateCourse() {
    //UseState react hook lets to add state variables-mode(page responsiveness),save(button state),courseId
    const [mode, setMode] = useState("");
    const [save, setSave] = useState(true);
    const [preview, setPreview] = useState(false);
    const [next, setNext] = useState(false);
    const [courseId, setCourseId] = useState("");
    //UseRef react hook lets to pass value not required for rendering-next step state
    const nextStep = useRef(null);
    //react hook to navigate programmatically
    const navigate = useNavigate();

    //function to handle page responsiveness
    const handleResize = useCallback(() => {
        const width = window.innerWidth;

        if (width > 768) {
            setMode('horizontal');
        } else if (width <= 768 && width > 400) {
            setMode('vertical');
        } else {
            setMode('mobile');
        }
    }, []);

    //useEffect react hook renders when page loads and rerenders when parameters described changes(handleResize)
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    const gotoDashboard = () => {
        navigate("/dashboard");
    };

    return (
        <>
            <div>
                <Header/>
            </div>
            <div style={{padding: "20px", marginLeft: "50px", marginRight: "50px"}}>
                <MDBStepper type={mode} style={{minHeight: "100vh"}} linear noEditable externalNext={nextStep}
                            disableHeadSteps>
                    <MDBStepperForm>
                        <MDBStepperStep headIcon={1} itemId={1}>
                            <CourseStructure setSave={setSave}/>
                        </MDBStepperStep>
                        <MDBStepperStep headIcon={2} itemId={2}>
                            <CourseDetails save={save} setSave={setSave} setCourseId={setCourseId}/>
                        </MDBStepperStep>
                        <MDBStepperStep headIcon={3} itemId={3}>
                            <CreateLectures save={save} setSave={setSave} courseId={courseId}/>
                        </MDBStepperStep>
                        <MDBStepperStep headIcon={4} itemId={4}>
                            <AddLectureQuestions save={save} setSave={setSave} courseId={courseId}/>
                        </MDBStepperStep>
                        <MDBStepperStep headIcon={5} itemId={5}>
                            <AddSupplementaryMaterial save={save} setSave={setSave} setPreview={setPreview} courseId={courseId}/>
                        </MDBStepperStep>
                        {/* <MDBStepperStep headIcon={6} itemId={6}>
                            <CreateCertificate/>
                        </MDBStepperStep> */}
                        <MDBStepperStep headIcon={6} itemId={6}>
                            {preview&&
                            <Preview courseId={courseId} save={save} setSave={setSave} setNext={setNext}/>
                            }
                        </MDBStepperStep>
                    </MDBStepperForm>
                </MDBStepper>
                <MDBBtn ref={nextStep} disabled={!save} onClick={() => {setSave(false);{next && gotoDashboard()}}} rounded>
                    Next
                </MDBBtn>
            </div>
        </>
    );
}

export default CreateCourse;
