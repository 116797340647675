import React, {useState, useEffect, useContext} from "react";
import {useMDBCookies} from "@krtrimaiq-cognitive-solutions/mdb-react-cookies-management";
import axios from "axios";
import {UrlContext} from "../../App";
import {MDBBtn} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";

function DownloadCertificate() {
    const [downloadUrl, setDownloadUrl] = useState([]);
    const [userFirstName] = useMDBCookies("user-firstname", "");
    const [userLastName] = useMDBCookies("user-lastname", "");
    const {lastsection} = useContext(UrlContext);

    function handleDownload() {
        window.location.replace(
            "https://mmc-content.s3.ap-south-1.amazonaws.com/certificate.pdf"
        );
    }

    useEffect(() => {
        let userData = {
            name: userFirstName + userLastName,
            start_date: "28 May 2022",
            end_date: "8 June 2022",
            certificate_date: "10 June 2022",
        };
        axios.post(
            "https://3ehdllj4j9.execute-api.ap-south-1.amazonaws.com/mmc/get-certificate", JSON.stringify(userData)
            )
            .then((response) => setDownloadUrl(response.data));
    }, [downloadUrl, setDownloadUrl, userFirstName, userLastName]);
    return (
        <>
            <MDBBtn disabled={!lastsection} type="submit" onClick={handleDownload} className={"btn-primary"}>
                Download Certificate
            </MDBBtn>
        </>
    );
}

export default DownloadCertificate;
