import React from 'react';
import {MDBCard, MDBCardBody} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import Ratings from "./Ratings";


const Reviews = ({reviews, ratings, className}) => {
if(reviews.length>0)
{
    return (
        <div className={className}>
            {reviews.map((review, index) =>
                <MDBCard key={index} className={"d-flex flex-row my-2"} style={{textAlign: "start"}}>
                    <MDBCardBody className={"d-flex flex-column"}>
                        <div className={"d-flex flex-row justify-content-between"}>
                            <div style={{fontSize: "22px", fontWeight: 500}}>
                                {review.user[0].firstName} {review.user[0].lastName}
                            </div>
                            { ratings.length>0 ?
                             <Ratings readonly rating={ratings.filter((rating) => {return rating.userId['$oid'] === review.userId['$oid']})[0].rating}/>
                            : <Ratings readonly rating={0}/>
                          }
                        </div>
                        <div className={"mt-2"}>
                            {review.review}
                        </div>
                    </MDBCardBody>
                </MDBCard>
            )}
        </div>
    )
  }
  else
  {
    return(
        <div className={"mt-2 mr-2"} style={{fontSize: "15px", textAlign: "start"}}>
        Course Reviews Not Available
    </div>
    )
  }
}

export default Reviews;