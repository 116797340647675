import React from "react";
import {MDBCard, MDBContainer} from "@krtrimaiq-cognitive-solutions/mdb-react-ui-kit";
import {CourseStructureData} from "./CourseData"

function CourseStructure() {
    return (
        <>
            <MDBContainer className={"d-flex h-100"}>
                <div style={{width: "100%", height: "100%"}}>
                    <h3 className="mb-4 text-start" style={{color: "navy"}}>
                        Course Structure
                    </h3>
                    <div>
                        <MDBCard className={"p-3 my-2 text-start"}>
                            <div>
                                <h5>There's a course in you. Plan it out.</h5>
                                <p>
                                    Planning your course carefully will create a clear learning
                                    path for students and help you once you film. Think down to
                                    the details of each lecture including the skill you'll teach
                                    , estimated video length,practical activities to include,
                                    and how you'll create introductions and summaries.
                                </p>
                            </div>
                        </MDBCard>
                    </div>

                    <h5 className="mb-3 mt-4 text-start">
                        Tips
                    </h5>
                    <div className="text-start d-flex flex-column">
                        {
                            CourseStructureData.map((info, index) => {
                                return (
                                    <MDBCard className="mb-3" key={index}>
                                        <div key={info.id}>
                                            <h5 className="px-3 mt-4">
                                                {info.title}
                                            </h5>
                                            <p className="px-3 mb-3">
                                                {info.content}
                                            </p>
                                        </div>
                                    </MDBCard>
                                );
                            })
                        }
                    </div>
                </div>
            </MDBContainer>
        </>
    );
}

export default CourseStructure;
