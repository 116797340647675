import React from 'react';
import {
  MDBCard,
  MDBCardText,
} from '@krtrimaiq-cognitive-solutions/mdb-react-ui-kit';
import Header from './Header';
import instructor from '../../assets/instructor.jpg';

const Announcement = () => {
  return (
    <div>
      <Header/>
      <div className='m-4'
                  style={{
                    fontSize: '20px',
                    fontWeight: 500,
                    textAlign: 'start',
                  }}
                >
                  Announcement
                </div>
                <div className='ps-3 d-flex justify-content-start align-items-start'>
                <button type="button" class="m-2 btn btn-tertiary btn-rounded" data-mdb-ripple-color="light">Create Announcement</button>
                <button type="button" class="m-2 btn btn-tertiary btn-rounded" data-mdb-ripple-color="light">Delete</button>
                </div>
                <MDBCard className='m-3'>
                <div className='ps-3 m-3 d-flex justify-content-start align-items-start'>
                 <input class="form-check-input justify-content-between align-items-center me-2" type="checkbox" id="checkboxNoLabel" value="" aria-label="..." />
                 <img
                    src={instructor}
                    class="rounded-circle"
                    alt=""
                    style={{ width: '45px', height: '45px' }}
                  />
                  <MDBCardText>
                  Announcement1
                  </MDBCardText>
                  </div>
                  
                </MDBCard>
                <MDBCard className='m-3'>
                <div className='ps-3 m-3 d-flex justify-content-start align-items-start'>
                 <input class="form-check-input justify-content-between align-items-center me-2" type="checkbox" id="checkboxNoLabel" value="" aria-label="..." />
                 <img
                    src={instructor}
                    class="rounded-circle"
                    alt=""
                    style={{ width: '45px', height: '45px' }}
                  />
                  <MDBCardText>
                  Announcement2
                  </MDBCardText>
                  </div>
                  
                </MDBCard>
    </div>
  );
};

export default Announcement;